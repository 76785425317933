




  
export default 
    name: 'dualBarChart'
    props: [ 'data', 'topMargin', 'height' ]
    data:() ->
        chartData: {}

    watch:
        data:
            deep: true
            handler:() ->
                @initiate()

    mounted:() ->
        this.initiate()

    methods: 
        chartHeight: ()->
            if this.height
                return this.height
            else if window.screen.width < 600
                return 500
            else return 700
        ChartMarginTop:()->
            if this.topMargin
                return this.topMargin
            else
                return '60'

        labelFontSize:()->
            if window.screen.width < 600
                return 9
            else return;

        legendFontSize:()->
            if window.screen.width < 600
                return "2vw"
            else return "0.8vw"
            
        legendPosition:()->
            if window.screen.width < 600
                return "center"
            else return "right"
        
        symbolHeight:()->
            if window.screen.width < 600
                return 7
            else return 14

        symbolRadius:()->
            if window.screen.width < 600
                return 2
            else return 4

        initiate:() ->
            self = this
            if !self.data.seriesData.length
                return
            seriesData = [{
                lineWidth: 3
                marker: 
                    states:
                        hover: 
                            radiusPlus: 3,
                            lineWidthPlus: 3
                name: self.data.seriesData[0].name
                type: 'bar'
                yAxis: 0
                color: '#47A694'
                data: self.data.seriesData[0].data
            }]
            yAxis = [{
                labels:
                    formatter: ()->
                        @value.toLocaleString 'en-US'

                lineWidth: 0
                crosshair:true
                gridLineWidth: 1
                inverted: true
                title:
                    text: self.data.seriesData[0].name, 
                    style: 
                        color: "#065559"

            }]
            if self.data.seriesData.length > 1
                seriesData.push({
                    yAxis: 1
                    lineWidth: 3
                    marker: 
                        states:
                            hover: 
                                radiusPlus: 3,
                                lineWidthPlus: 3,
                    color: '#265950'
                    name: self.data.seriesData[1].name
                    type: 'bar'
                    data: self.data.seriesData[1].data
                })
                yAxis.push({
                    title: 
                        text: self.data.seriesData[1].name
                        margin: 0
                        style: 
                            color: "#065559"
                    opposite: true
                })
            self.chartData =
                chart: {
                    type: 'bar'
                    marginTop: this.ChartMarginTop(),
                    backgroundColor:'transparent'
                    height: this.chartHeight()
                }
                plotOptions:
                    bar: 
                        groupPadding: 0.1
                        pointPadding: 0
                        dataLabels:
                            enabled: true
                            style: 
                                fontWeight: 0
                            formatter: ->
                                Number(@point.y.toFixed(2)).toLocaleString()
                title: 
                    text: ''
                subtitle: 
                    text: ''
                credits:
                    enabled: false
                legend:
                    itemStyle:  
                        fontSize: self.legendFontSize()
                    enabled: true
                    alignColumns: true
                    align: self.legendPosition()
                    y: 0
                    x: 0
                    squareSymbol: true
                    verticalAlign: "top"
                    itemDistance: 35
                    margin: 0
                    padding: 0
                    symbolHeight: this.symbolHeight()
                    symbolWidth: this.symbolHeight()
                    symbolRadius: this.symbolRadius()

                xAxis: [ {
                    categories: self.data.xAxis
                    crosshair: true
                    labels:
                        style:
                            fontSize: self.labelFontSize()
                } ]
                yAxis: yAxis
                tooltip:
                    shared: true
                    formatter: ->
                        i = undefined
                        mytooltip = undefined
                        rs = undefined
                        mytooltip = '<b>' + @points[0].key + '</b>'
                        rs = ' '
                        i = 0
                        while i < @points.length
                            mytooltip += '<br/>' + '<span style="color:' + @points[i].series.color + '">●</span> ' + @points[i].series.name + ': <b>' + rs + @points[i].y.toLocaleString('en-US') + '</b>'
                            i++
                        mytooltip
                    borderWidth: 0
                    shadow: {
                        color: "#d6d6d6",
                        opacity: 0.1,
                        width: 10,
                    }
                    borderRadius: 6
                    backgroundColor: "#ffffff"
                series: seriesData
