






  
import moment from 'moment';
export default 
  name: 'dualAxisChart'
  props: [ 'data','topMargin', 'title']
  data:() ->
    chartData: {},

  watch:
    data:
      deep: true
      handler:() ->
        @initiate()
      
  mounted:() ->
    this.initiate()
    
  methods: 
    formatXAxis:(dateArray)->    
      resultArray = []
      for dateString in dateArray
        dateObj1 = moment(dateString, 'DD MMM YYYY', true)
        dateObj2 = moment(dateString, 'YYYY-MM-DD', true)
        if dateObj1.isValid()
          resultArray.push(dateObj1.format('DD MMM'))
        else if dateObj2.isValid()
          resultArray.push(dateObj2.format('DD MMM'))
        else
          resultArray.push(dateString)
      resultArray

    ChartMarginTop:()->
      if this.topMargin
        return this.topMargin
      else
        return '50'
    
    yTitleSize:()->
      if window.screen.width < 600
        return 9
      else return

    legendFontSize:()->
      if window.screen.width < 600
        return "2vw"
      else return "0.8vw"
    
    legendPosition:()->
      if window.screen.width < 600
        return "center"
      else return "right"
    
    symbolHeight:()->
      if window.screen.width < 600
        return 7
      else return 14

    symbolRadius:()->
      if window.screen.width < 600
        return 2
      else return 4

    abbreviateNumber:(value) ->
      if window.screen.width < 600
        Intl.NumberFormat('en-US', {
          notation: "compact",
          maximumFractionDigits: 2
        }).format(value);
      else return value.toLocaleString 'en-US'
    
    lineMarker:()->
      if window.screen.width < 600
        return 
          enabled: true,
          radius: 1.5,
          states: 
            hover:
              radiusPlus: 1,
              lineWidthPlus: 1
      else 
        return 
          states:
            hover: 
              radiusPlus: 3,
              lineWidthPlus: 3,
      
    labelFontSize:()->
      if window.screen.width < 600
        return 9
      else return;
    

    initiate:() ->
      self = this
      if !self.data.seriesData.length
        return
      seriesData = [{
        name: self.data.seriesData[0].name
        type: 'column'
        yAxis: 0
        color: '#47A694'
        borderWidth:0
        data: self.data.seriesData[0].data
      }]
      yAxis = [{
        labels: 
          formatter: ->
            self.abbreviateNumber(@value)
        lineWidth: 0
        crosshair:true
        gridLineWidth: 1
        title:
          text: self.data.seriesData[0].name, 
          style: 
            color: "#065559"
            fontSize: self.yTitleSize()

      }]
      if self.data.seriesData.length > 1
        i = 1
        while i < self.data.seriesData.length
          seriesData.push({
            yAxis: 1
            lineWidth: 1
            marker: self.lineMarker()              
            color: self.data.seriesData[i].color
            name: self.data.seriesData[i].name
            type: 'line'
            data: self.data.seriesData[i].data
          })  
          yAxis.push({
            labels: 
              formatter: ->
                self.abbreviateNumber(@value)
            title: 
              text: self.data.seriesData[i].name
              style: 
                color: self.data.seriesData[i].color
                fontSize: self.yTitleSize()
            opposite: true
          })
          i++
      self.chartData =
        chart: {
          marginTop: this.ChartMarginTop(),
          backgroundColor:'transparent',
          height: 60 + '%'
        }
        title: 
          text: ''
        subtitle: 
          text: ''
        credits:
          enabled: false
        legend:
          itemStyle:  
            fontSize: self.legendFontSize()
          enabled: true
          alignColumns: true
          align: self.legendPosition()
          y: 0
          x: 0
          squareSymbol: true
          verticalAlign: "top"
          itemDistance: 35
          margin: 0
          padding: 0
          symbolHeight: this.symbolHeight()
          symbolWidth: this.symbolHeight()
          symbolRadius: this.symbolRadius()

        xAxis: [ {
          categories: self.formatXAxis(self.data.xAxis)
          crosshair: true
          labels:
            style:
              fontSize: self.labelFontSize()
        } ]
        yAxis: yAxis
        tooltip:
          shared: true
          formatter: ->
            i = undefined
            mytooltip = undefined
            rs = undefined
            mytooltip = '<b>' + @points[0].key + '</b>'
            rs = ' '
            i = 0
            while i < @points.length
              mytooltip += '<br/>' + '<span style="color:' + @points[i].series.color + '">●</span> ' + @points[i].series.name + ': <b>' + rs + @points[i].y.toLocaleString('en-US') + '</b>'
              i++
            mytooltip
          borderWidth: 0
          shadow: {
            color: "#d6d6d6",
            opacity: 0.1,
            width: 10,
          }
          borderRadius: 6
          backgroundColor: "#ffffff"

        series: seriesData            
