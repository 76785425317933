import axios from 'axios'
import Header from '@/components/Header/Header.vue'
import dualAxisChart from '@/components/charts/dualAxisChart.vue'
import dualBarChart from '@/components/charts/dualBarChart.vue'
import stackChart from '@/components/charts/stackChart.vue'
import Vuetable from 'vuetable-2'
import VuetableFieldHandle from 'vuetable-2/src/components/VuetableFieldHandle.vue'
import moment from 'moment'
import Loader from '@/components/Loader/Loader.vue'
// import MTDJson from './Summary.json'


//api -:
// https://weavr.online/api/adword/detailed?table_type=month&start_date=2022-08-23&end_date=2022-09-22
// https://weavr.online/api/adword/detailed?table_type=campaign&start_date=2022-08-23&end_date=2022-09-22
// https://weavr.online/api/adword/detailed?table_type=camp_type&start_date=2022-08-23&end_date=2022-09-22
// https://weavr.online/api/adword/detailed?table_type=channel&start_date=2022-08-23&end_date=2022-09-22
// https://weavr.online/api/adword/detailed?table_type=geo&start_date=2022-08-23&end_date=2022-09-22&type=country



export default {
    name: 'AdSummary',
    components: {
        Header,
        Vuetable,
        Loader,
        dualAxisChart,
        stackChart,
        dualBarChart
    },
    data() {
        return {
            monthDataLoad: false,
            campaignDataLoad: false,
            campaignTypeDataLoad: false,
            deviceTypeDataLoad: false,
            regionDataLoad: false,
            metricDropdown : 'clicks_impression',
            activeCompare: false,
            dateRange: {
                startDate: moment().subtract(1, 'months'),
                endDate: moment()
            },
            monthTableData: {
                header: [
                    { key: "month", label: "Month" },
                    { key: "impressions", label: "Impressions" },
                    { key: "clicks", label: "Clicks" },
                    { key: "cost", label: "Cost (Rs.)" },
                    { key: "ctr", label: "CTR (%)" },
                    { key: "cpc", label: "CPC (Rs.)" },
                    { key: "conversions", label: "Conversion" },
                    { key: "cost_conversion", label: "Cost/ Conversion (Rs.)" },
                    { key: "revenue", label: "Revenue (Rs.)" },
                    { key: "impression_share", label: "Impression Share(%)" }
                ],
                data: [],
                currentTableState: {},
                chart: {
                    xAxis: [],
                    seriesData: []
                }
            },
            campaignTableData: {
                header: [
                    { key: "campaign_name", label: "Campaign Name" },
                    { key: "impressions", label: "Impressions" },
                    { key: "clicks", label: "Clicks" },
                    { key: "cost", label: "Cost (Rs.)" },
                    { key: "ctr", label: "CTR (%)" },
                    { key: "cpc", label: "CPC (Rs.)" },
                    { key: "conversions", label: "Conversion" },
                    { key: "cost_conversion", label: "Cost/ Conversion (Rs.)" },
                    { key: "revenue", label: "Revenue (Rs.)" },
                    { key: "impression_share", label: "Impression Share(%)" }
                ],
                data: [],
                currentTableState: {},
                chart: {
                    xAxis: [],
                    seriesData: []
                }
            },
            campaignTypeTableData: {
                header: [
                    { key: "type", label: "Network" },
                    { key: "impressions", label: "Impressions" },
                    { key: "clicks", label: "Clicks" },
                    { key: "cost", label: "Cost (Rs.)" },
                    { key: "ctr", label: "CTR (%)" },
                    { key: "cpc", label: "CPC (Rs.)" },
                    { key: "conversions", label: "Conversion" },
                    { key: "cost_conversion", label: "Cost/ Conversion (Rs.)" },
                    { key: "revenue", label: "Revenue (Rs.)" },
                    { key: "impression_share", label: "Impression Share(%)" }
                ],
                data: [],
                currentTableState: {},
                chart: {
                    xAxis: [],
                    seriesData: []
                }
            },
            deviceTypeTableData: {
                header: [
                    { key: "type", label: "Device" },
                    { key: "impressions", label: "Impressions" },
                    { key: "clicks", label: "Clicks" },
                    { key: "cost", label: "Cost (Rs.)" },
                    { key: "ctr", label: "CTR (%)" },
                    { key: "cpc", label: "CPC (Rs.)" },
                    { key: "conversions", label: "Conversion" },
                    { key: "cost_conversion", label: "Cost/ Conversion (Rs.)" },
                    { key: "revenue", label: "Revenue (Rs.)" },
                    { key: "impression_share", label: "Impression Share(%)" }
                ],
                data: [],
                currentTableState: {},
                chart: {
                    xAxis: [],
                    seriesData: []
                }
            },
            regionTableData: {
                header: [
                    { key: "level", label: "Region" },
                    { key: "impressions", label: "Impressions" },
                    { key: "clicks", label: "Clicks" },
                    { key: "cost", label: "Cost (Rs.)" },
                    { key: "ctr", label: "CTR (%)" },
                    { key: "cpc", label: "CPC (Rs.)" },
                    { key: "conversions", label: "Conversion" },
                    { key: "cost_conversion", label: "Cost/ Conversion (Rs.)" },
                    { key: "revenue", label: "Revenue (Rs.)" }
                ],
                data: [],
                currentTableState: {},
                chart: {
                    xAxis: [],
                    seriesData: []
                },
                metricDropdown: 'country'
            },

            fields: [
                {
                    name: VuetableFieldHandle
                },
                {
                    name: 'name',
                    title: '<span class="orange glyphicon glyphicon-user"></span> Full Name',
                    sortField: 'name'
                },
                {
                    name: 'email',
                    sortField: 'email'
                },
                'birthdate',
                'nickname',
                {
                    name: 'gender',
                    formatter(value) {
                        if (value == 'M') {
                            return 'Male'
                        }
                        else
                            return 'Female'
                    }
                }
            ],
            data: []
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.formatDateRange();
            this.dataAPIs();          
        },

        formatDateRange(){
            this.dateRange.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            this.dateRange.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD');
        },

        formatDates() {
            this.formatDateRange();
            this.destroyAllTables();
			this.dataAPIs();
        },


        formatNumber(num) {
            if (isNaN(num)) {
                return num
            }
            const num1 = Number(num).toFixed(2);
            const num2 = Number(num1).toLocaleString();
            return num2;
        },

        activeCompareFn() {
            this.activeCompare = !this.activeCompare
        },

        dataAPIs() {
            this.monthDataLoad = true;
            this.campaignDataLoad = true;
            this.campaignTypeDataLoad = true;
            this.deviceTypeDataLoad = true;
            this.regionDataLoad = true;

            this.getMonthData();
            this.getCampaignData();
            this.getCampaignTypeData();
            this.getDeviceTypeData();
            this.getRegionData();
    
        },

        destroyAllTables(){
            $('#month-tbl').DataTable().destroy();
			$('#campaigndata-tbl').DataTable().destroy();
			$('#devicetype-tbl').DataTable().destroy();
            $('#region-tbl').DataTable().destroy();
            $('#campaigntype-tbl').DataTable().destroy();
        },

        reinitializeRegionDropdown(){
            this.destroyAllTables();
            this.dataAPIs();
        },
        
        processAllGraphs(){
            this.processMonthGraph();
            this.processCampaignDataGraph();
            this.processCampaignTypeGraph();
            this.processDeviceTypeGraph();
            this.processRegionGraph();
        },
        

        processMonthGraph(){
            this.monthTableData.chart = {
                xAxis: [],
                seriesData: []
            }
            const xaxis = this.monthTableData.currentTableState.monthData;
            const seriesData = [];

            if (this.metricDropdown == 'clicks_impression'){
                seriesData.push({name: 'Clicks', data: this.monthTableData.currentTableState.clickData });
                seriesData.push({name: 'Impressions', data: this.monthTableData.currentTableState.impressionData, color: "#FCB116"})
            }else if (this.metricDropdown == 'cost_conv-conversion') {
                seriesData.push({name: 'Cost (Rs.)', data: this.monthTableData.currentTableState.costData });
                seriesData.push({name: 'Cost/Conversion (Rs.)', data: this.monthTableData.currentTableState.cost_conversionData, color: "#FCB116" });
            }else if (this.metricDropdown == 'cost_revenue') {
                seriesData.push({name: 'Cost (Rs.)', data: this.monthTableData.currentTableState.costData });
                seriesData.push({name: 'Revenue (Rs.)', data: this.monthTableData.currentTableState.revenueData, color: "#FCB116" });
            }
            
            this.monthTableData.chart = {
                xAxis: xaxis,
                seriesData: seriesData
            }
            this.monthDataLoad = false;
        },
        processCampaignDataGraph(){
            this.campaignTableData.chart = {
                xAxis: [],
                seriesData: []
            }
            const xaxis = this.campaignTableData.currentTableState.campaignNameData;
            const seriesData = [];

            if (this.metricDropdown == 'clicks_impression'){
                seriesData.push({name: 'Clicks', data: this.campaignTableData.currentTableState.clickData });
                seriesData.push({name: 'Impressions', data: this.campaignTableData.currentTableState.impressionData})
            }else if (this.metricDropdown == 'cost_conv-conversion') {
                seriesData.push({name: 'Cost (Rs.)', data: this.campaignTableData.currentTableState.costData });
                seriesData.push({name: 'Cost/Conversion (Rs.)', data: this.campaignTableData.currentTableState.cost_conversionData });
            }else if (this.metricDropdown == 'cost_revenue') {
                seriesData.push({name: 'Cost (Rs.)', data: this.campaignTableData.currentTableState.costData });
                seriesData.push({name: 'Revenue (Rs.)', data: this.campaignTableData.currentTableState.revenueData });
            }
            
            this.campaignTableData.chart = {
                xAxis: xaxis,
                seriesData: seriesData
            }
            this.campaignDataLoad = false;
        },
        processCampaignTypeGraph(){
            this.campaignTypeTableData.chart = {
                xAxis: [],
                seriesData: []
            }
            const xaxis = this.campaignTypeTableData.currentTableState.networkData;
            const seriesData = [];

            if (this.metricDropdown == 'clicks_impression'){
                seriesData.push({name: 'Clicks', data: this.campaignTypeTableData.currentTableState.clickData });
                seriesData.push({name: 'Impressions', data: this.campaignTypeTableData.currentTableState.impressionData, color: "#FCB116" })
            }else if (this.metricDropdown == 'cost_conv-conversion') {
                seriesData.push({name: 'Cost (Rs.)', data: this.campaignTypeTableData.currentTableState.costData });
                seriesData.push({name: 'Cost/Conversion (Rs.)', data: this.campaignTypeTableData.currentTableState.cost_conversionData, color: "#FCB116" });
            }else if (this.metricDropdown == 'cost_revenue') {
                seriesData.push({name: 'Cost (Rs.)', data: this.campaignTypeTableData.currentTableState.costData });
                seriesData.push({name: 'Revenue (Rs.)', data: this.campaignTypeTableData.currentTableState.revenueData, color: "#FCB116" });
            }

            this.campaignTypeTableData.chart = {
                xAxis: xaxis,
                seriesData: seriesData
            }
            this.campaignTypeDataLoad = false;
        },
        processDeviceTypeGraph(){
            this.deviceTypeTableData.chart = {
                xAxis: [],
                seriesData: []
            }
            const xaxis = this.deviceTypeTableData.currentTableState.deviceData;
            const seriesData = [];

            if (this.metricDropdown == 'clicks_impression'){
                seriesData.push({name: 'Clicks', data: this.deviceTypeTableData.currentTableState.clickData });
                seriesData.push({name: 'Impressions', data: this.deviceTypeTableData.currentTableState.impressionData, color: "#FCB116"})
            }else if (this.metricDropdown == 'cost_conv-conversion') {
                seriesData.push({name: 'Cost (Rs.)', data: this.deviceTypeTableData.currentTableState.costData });
                seriesData.push({name: 'Cost/Conversion (Rs.)', data: this.deviceTypeTableData.currentTableState.cost_conversionData, color: "#FCB116" });
            }else if (this.metricDropdown == 'cost_revenue') {
                seriesData.push({name: 'Cost (Rs.)', data: this.deviceTypeTableData.currentTableState.costData });
                seriesData.push({name: 'Revenue (Rs.)', data: this.deviceTypeTableData.currentTableState.revenueData, color: "#FCB116" });
            }
            
            this.deviceTypeTableData.chart = {
                xAxis: xaxis,
                seriesData: seriesData
            }
            this.deviceTypeDataLoad = false;
        },
        processRegionGraph(){
            this.regionTableData.chart = {
                xAxis: [],
                seriesData: []
            }
            const xaxis = this.regionTableData.currentTableState.regionData;
            const seriesData = [];

            if (this.metricDropdown == 'clicks_impression'){
                seriesData.push({name: 'Clicks', data: this.regionTableData.currentTableState.clickData });
                seriesData.push({name: 'Impressions', data: this.regionTableData.currentTableState.impressionData, color: "#FCB116" })
            }else if (this.metricDropdown == 'cost_conv-conversion') {
                seriesData.push({name: 'Cost (Rs.)', data: this.regionTableData.currentTableState.costData });
                seriesData.push({name: 'Cost/Conversion (Rs.)', data: this.regionTableData.currentTableState.cost_conversionData, color: "#FCB116" });
            }else if (this.metricDropdown == 'cost_revenue') {
                seriesData.push({name: 'Cost (Rs.)', data: this.regionTableData.currentTableState.costData });
                seriesData.push({name: 'Revenue (Rs.)', data: this.regionTableData.currentTableState.revenueData, color: "#FCB116" });
            }
            
            this.regionTableData.chart = {
                xAxis: xaxis,
                seriesData: seriesData
            }
            this.regionDataLoad = false;
        },


        processMonthTable() {
            const monthTable = $('#month-tbl').DataTable({
                info: false,
                sorting: [[1, "desc"]],
                searching: false,
                columnDefs: [
                    { type: 'date-range', targets: 0 }
                ],
                language: {
                    search: "",
                    searchPlaceholder: "Search"
                }
            });
            monthTable.on('draw.dt', () => {
                var monthData, costData, cost_conversionData, revenueData, impressionData, clickData;
                $('#month-tbl_paginate').remove();
                $('#month-tbl_length').remove();
                // $('#month-tbl thead').remove();
                monthData = [];
                cost_conversionData = [];
                revenueData = [];
                costData = [];
                impressionData = [];                
                clickData = [];

                Array.from(monthTable.rows({filter: 'applied'}).data()).forEach((value, index) => {
                    if (index < 10) {
                        monthData.push(value[0]);
                        cost_conversionData.push(Number(value[7].replace(/,/g, '')));
                        revenueData.push(Number(value[8].replace(/,/g, '')));
                        costData.push(Number(value[3].replace(/,/g, '')));
                        impressionData.push(Number(value[1].replace(/,/g, '')));
                        clickData.push(Number(value[2].replace(/,/g, '')))
                    }
                });
                this.monthTableData.currentTableState = {
                    monthData: monthData,
                    cost_conversionData: cost_conversionData,
                    revenueData: revenueData,
                    costData: costData,
                    impressionData: impressionData,
                    clickData: clickData
                };  
                this.processMonthGraph();
            });
            $('#month-tbl').DataTable().draw();
        },
        processCampaignDataTable() {
            const campaignTable = $('#campaigndata-tbl').DataTable({
                info: false,
                sorting: [[1, "desc"]],
                searching: false,
                language: {
                    search: "",
                    searchPlaceholder: "Search"
                },
            });
            
            campaignTable.on('draw.dt', () => {
                var campaignNameData, costData, cost_conversionData, revenueData, impressionData, clickData;
                $('#campaigndata-tbl_paginate').remove();
                $('#campaigndata-tbl_length').remove();
                // $('#campaigndata-tbl thead').remove();
                
                campaignNameData = [];
                cost_conversionData = [];
                revenueData = [];
                costData = [];
                impressionData = [];                
                clickData = [];
                Array.from(campaignTable.rows({filter: 'applied'}).data()).forEach((value, index) => {
                    if (index < 10) {
                        campaignNameData.push(value[0]);
                        cost_conversionData.push(Number(value[7].replace(/,/g, '')));
                        revenueData.push(Number(value[8].replace(/,/g, '')));
                        costData.push(Number(value[3].replace(/,/g, '')));
                        impressionData.push(Number(value[1].replace(/,/g, '')));
                        clickData.push(Number(value[2].replace(/,/g, '')))
                    }
                });

                this.campaignTableData.currentTableState = {
                    campaignNameData: campaignNameData,
                    cost_conversionData: cost_conversionData,
                    revenueData: revenueData,
                    costData: costData,
                    impressionData: impressionData,
                    clickData: clickData
                };  
                this.processCampaignDataGraph();
            });
            $('#campaigndata-tbl').DataTable().draw();
            
        },
        processCampaignTypeTable() {
            const campaignTypeTable = $('#campaigntype-tbl').DataTable({
                info: false,
                sorting: [[1, "desc"]],
                searching: false,
                language: {
                    search: "",
                    searchPlaceholder: "Search"
                }
            });
            
            campaignTypeTable.on('draw.dt', () => {
                var networkData, costData, cost_conversionData, revenueData, impressionData, clickData;
                $('#campaigntype-tbl_paginate').remove();
                $('#campaigntype-tbl_length').remove();
                // $('#campaigntype-tbl thead').remove();
                
                networkData = [];
                cost_conversionData = [];
                revenueData = [];
                costData = [];
                impressionData = [];                
                clickData = [];
                Array.from(campaignTypeTable.rows({filter: 'applied'}).data()).forEach((value, index) => {
                    if (index < 10) {
                        networkData.push(value[0]);
                        cost_conversionData.push(Number(value[7].replace(/,/g, '')));
                        revenueData.push(Number(value[8].replace(/,/g, '')));
                        costData.push(Number(value[3].replace(/,/g, '')));
                        impressionData.push(Number(value[1].replace(/,/g, '')));
                        clickData.push(Number(value[2].replace(/,/g, '')));
                    }
                });
                this.campaignTypeTableData.currentTableState = {
                    networkData: networkData,
                    cost_conversionData: cost_conversionData,
                    revenueData: revenueData,
                    costData: costData,
                    impressionData: impressionData,
                    clickData: clickData
                };
                this.processCampaignTypeGraph();
            });
            $('#campaigntype-tbl').DataTable().draw();
        },
        processDeviceTypeTable() {
            const deviceTypeTable = $('#devicetype-tbl').DataTable({
                info: false,
                sorting: [[1, "desc"]],
                searching: false,
                language: {
                    search: "",
                    searchPlaceholder: "Search"
                }
            });
            deviceTypeTable.on('draw.dt', () => {
                var deviceData, costData, cost_conversionData, revenueData, impressionData, clickData;
                $('#devicetype-tbl_paginate').remove();
                $('#devicetype-tbl_length').remove();
                // $('#devicetype-tbl thead').remove();
                
                deviceData = [];
                cost_conversionData = [];
                revenueData = [];
                costData = [];
                impressionData = [];                
                clickData = [];
                Array.from(deviceTypeTable.rows({filter: 'applied'}).data()).forEach((value, index) => {
                    if (index < 10) {
                        deviceData.push(value[0]);
                        cost_conversionData.push(Number(value[7].replace(/,/g, '')));
                        revenueData.push(Number(value[8].replace(/,/g, '')));
                        costData.push(Number(value[3].replace(/,/g, '')));
                        impressionData.push(Number(value[1].replace(/,/g, '')));
                        clickData.push(Number(value[2].replace(/,/g, '')))
                    }
                });
                this.deviceTypeTableData.currentTableState = {
                    deviceData: deviceData,
                    cost_conversionData: cost_conversionData,
                    revenueData: revenueData,
                    costData: costData,
                    impressionData: impressionData,
                    clickData: clickData
                };
                this.processDeviceTypeGraph();
            });
            $('#devicetype-tbl').DataTable().draw();
        },
        processRegionTable() {
            const regionTable = $('#region-tbl').DataTable({
                info: false,
                sorting: [[1, "desc"]],
                searching: false,
                language: {
                    search: "",
                    searchPlaceholder: "Search"
                },
                dom: '<"top"if>rt<"bottom"lp><"clear">'
            });
            
            regionTable.on('draw.dt', () => {
                var regionData, costData, cost_conversionData, revenueData, impressionData, clickData;
                $('#region-tbl_paginate').remove();
                $('#region-tbl_length').remove();
                // $('#region-tbl thead').remove();
                
                regionData = [];
                cost_conversionData = [];
                revenueData = [];
                costData = [];
                impressionData = [];                
                clickData = [];

                Array.from(regionTable.rows({filter: 'applied'}).data()).forEach((value, index) => {
                    if (index < 10) {
                        regionData.push(value[0]);
                        cost_conversionData.push(Number(value[7].replace(/,/g, '')));
                        revenueData.push(Number(value[8].replace(/,/g, '')));
                        costData.push(Number(value[3].replace(/,/g, '')));
                        impressionData.push(Number(value[1].replace(/,/g, '')));
                        clickData.push(Number(value[2].replace(/,/g, '')))
                    }
                });
                this.regionTableData.currentTableState = {
                    regionData: regionData,
                    cost_conversionData: cost_conversionData,
                    revenueData: revenueData,
                    costData: costData,
                    impressionData: impressionData,
                    clickData: clickData
                };
                this.processRegionGraph();
            });
            $('#region-tbl').DataTable().draw();
        },

        //fetching API's
        getMonthData() {
            this.monthDataLoad = true;
            const url = `/api/adword/detailed?table_type=month&start_date=${this.dateRange.startDate}&end_date=${this.dateRange.endDate}`
            axios.get(url).then((res) => {
                const resData = res.data;
                this.monthTableData.data = resData.data;
                this.$nextTick(()=>{
                    this.processMonthTable();
                });
            }).catch((err) => console.log(err))
        },
        getCampaignData() {
            this.campaignDataLoad = true;
            const url = `/api/adword/detailed?table_type=campaign&start_date=${this.dateRange.startDate}&end_date=${this.dateRange.endDate}`
            axios.get(url).then((res) => {
                const resData = res.data;
                this.campaignTableData.data = resData.data;
                this.$nextTick(()=>{
                    this.processCampaignDataTable();
                });
            }).catch((err) => console.log(err))
        },
        getCampaignTypeData() {
            this.campaignTypeDataLoad = true;
            const url = `/api/adword/detailed?table_type=camp_type&start_date=${this.dateRange.startDate}&end_date=${this.dateRange.endDate}`
            axios.get(url).then((res) => {
                const resData = res.data;
                this.campaignTypeTableData.data = resData.data;
                this.$nextTick(()=>{
                    this.processCampaignTypeTable();
                });
            }).catch((err) => console.log(err))
        },
        getDeviceTypeData() {
            this.deviceTypeDataLoad = true;
            const url = `/api/adword/detailed?table_type=channel&start_date=${this.dateRange.startDate}&end_date=${this.dateRange.endDate}`
            axios.get(url).then((res) => {
                const resData = res.data;
                this.deviceTypeTableData.data = resData.data;
                this.$nextTick(()=>{
                    this.processDeviceTypeTable();
                });
            }).catch((err) => console.log(err))
        },
        getRegionData() {
            const type = this.regionTableData.metricDropdown;
            const url = `/api/adword/detailed?table_type=geo&start_date=${this.dateRange.startDate}&end_date=${this.dateRange.endDate}&type=${type}`;
            axios.get(url).then((res) => {
                const resData = res.data;
                this.regionTableData.data = resData.data;
                this.$nextTick(()=>{
                    this.processRegionTable();
                });
            }).catch((err) => console.log(err))
        },
    },
}
