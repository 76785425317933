
import moment from 'moment';
import AreaChart from './Components/AreaChartProcess.vue';
import AnalyticsDataTable from './Components/AnalyticsDataTable.vue';
import dualAxisChart from '@/components/charts/dualAxisChart.vue';
import AnalyticsHeatmap from './Components/MoMHeatMap.vue';
import NonEcomLineChart from './Components/NonEcomLineChart.vue';

export default {
    name: 'AnalyticsSummary',
    components: { AreaChart, AnalyticsDataTable, dualAxisChart, AnalyticsHeatmap, NonEcomLineChart },
    data() {
        return {
            dateRange: {
                startDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
                endDate: moment().format('YYYY-MM-DD')
            },
            componentRender: 0,
            channelArr: ['All'],
            locationArr: [
                { label: "City", value: "city" },
                { label: "Country", value: "country" }
            ],
            selectedLocation: "",
            filters: [
                {
                    key: "bestPerforming",
                    title: "Who/ What works for Us?",
                    sortCol: 3,
                    header: [
                        {
                            key: 'Variable',
                            label: 'Variable'
                        },
                        {
                            key: 'Device',
                            label: 'Device'
                        },
                        {
                            key: 'Age',
                            label: 'Age'
                        },
                        {
                            key: 'Gender',
                            label: 'Gender'
                        },
                        {
                            key: 'Location',
                            label: 'Location'
                        },
                        {
                            key: 'Channel',
                            label: 'Channel'
                        },
                    ]
                },
                {
                    key: "month",
                    title: "Monthly Totals (Last 6 months)",
                    sortCol: 2,
                    header: [
                        {
                            label: "Month",
                            key: "month"
                        },
                        {
                            label: "Transactions",
                            key: "Transactions"
                        },
                        {
                            label: "Revenue",
                            key: "Revenue"
                        },
                        {
                            label: "ASP",
                            key: "Avg Selling Price"
                        },
                        {
                            label: "AOV",
                            key: "Avg. Order Value"
                        },
                        {
                            label: "Transaction Rate",
                            key: "Transaction Rate"
                        },
                    ]
                },
                {
                    key: "productName",
                    title: "Top 10 Products",
                    sortCol: 3,
                    header: [
                        {
                            label: "Product Name",
                            key: "productName"
                        },
                        {
                            label: "Unique Purchases",
                            key: "Unuique Purchases"
                        },
                        {
                            label: "Quantity",
                            key: "quantity"
                        },
                        {
                            label: "Revenue",
                            key: "Revenue"
                        },
                        {
                            label: "ASP",
                            key: "Avg Selling Price"
                        },
                    ]
                },
                {
                    key: "channelGrouping",
                    title: "Channel",
                    sortCol: 3,
                    header: [
                        {
                            label: "Channel",
                            key: "channelGrouping"
                        },
                        {
                            label: "Users",
                            key: "Users"
                        },
                        {
                            label: "Transactions",
                            key: "Transactions"
                        },
                        {
                            label: "Revenue",
                            key: "Revenue"
                        },
                        {
                            label: "ASP",
                            key: "Avg Selling Price"
                        },
                        {
                            label: "AOV",
                            key: "Avg. Order Value"
                        },
                        {
                            label: "Transaction Rate",
                            key: "Transaction Rate"
                        },
                    ]
                },
                {
                    key: "sourceMedium",
                    title: "Paid Channels",
                    sortCol: 3,
                    header: [
                        {
                            label: "Paid Channels",
                            key: "sourceMedium"
                        },
                        {
                            label: "Users",
                            key: "Users"
                        },
                        {
                            label: "Transactions",
                            key: "Transactions"
                        },
                        {
                            label: "Revenue",
                            key: "Revenue"
                        },
                        {
                            label: "ASP",
                            key: "Avg Selling Price"
                        },
                        {
                            label: "AOV",
                            key: "Avg. Order Value"
                        },
                        {
                            label: "Transaction Rate",
                            key: "Transaction Rate"
                        },
                    ]
                },
                {
                    key: "mcf",
                    title: "Channel Interaction",
                    sortCol: 3,
                    header: [
                        {
                            label: "Channel Interaction",
                            key: "mcf"
                        },
                        {
                            label: "Assisted Conversion Value",
                            key: "Assisted Conversion Value"
                        },
                        {
                            label: "Assisted Conversions",
                            key: "Assisted Conversions"
                        },
                        {
                            label: "Total Conversions Value",
                            key: "Total Conversions Value"
                        },
                        {
                            label: "Last Interaction Value",
                            key: "Last Interaction Value"
                        },
                        {
                            label: "Total Conversions",
                            key: "Total Conversions"
                        },
                        {
                            label: "Last Interaction Conversions",
                            key: "Last Interaction Conversions"
                        },
                    ]
                },
                {
                    key: "deviceCategory",
                    title: "Device",
                    sortCol: 3,
                    header: [
                        {
                            label: "Device",
                            key: "deviceCategory"
                        },
                        {
                            label: "Users",
                            key: "Users"
                        },
                        {
                            label: "Transactions",
                            key: "Transactions"
                        },
                        {
                            label: "Revenue",
                            key: "Revenue"
                        },
                        {
                            label: "ASP",
                            key: "Avg Selling Price"
                        },
                        {
                            label: "AOV",
                            key: "Avg. Order Value"
                        },
                        {
                            label: "Transaction Rate",
                            key: "Transaction Rate"
                        },
                    ]
                },
                {
                    key: "userAgeBracket",
                    title: "Age",
                    sortCol: 3,
                    header: [
                        {
                            label: "Age",
                            key: "Age"
                        },
                        {
                            label: "Users",
                            key: "Users"
                        },
                        {
                            label: "Transactions",
                            key: "Transactions"
                        },
                        {
                            label: "Revenue",
                            key: "Revenue"
                        },
                        {
                            label: "ASP",
                            key: "Avg Selling Price"
                        },
                        {
                            label: "AOV",
                            key: "Avg. Order Value"
                        },
                        {
                            label: "Transaction Rate",
                            key: "Transaction Rate"
                        },
                    ]
                },
                {
                    key: "userGender",
                    title: "Gender",
                    sortCol: 3,
                    header: [
                        {
                            label: "Gender",
                            key: "Gender"
                        },
                        {
                            label: "Users",
                            key: "Users"
                        },
                        {
                            label: "Transactions",
                            key: "Transactions"
                        },
                        {
                            label: "Revenue",
                            key: "Revenue"
                        },
                        {
                            label: "ASP",
                            key: "Avg Selling Price"
                        },
                        {
                            label: "AOV",
                            key: "Avg. Order Value"
                        },
                        {
                            label: "Transaction Rate",
                            key: "Transaction Rate"
                        },
                    ]
                },
            ],
            locationTable: {
                key: "",
                title: "Location",
                sortCol: 3,
                header: [
                    {
                        label: "Location",
                        key: ""
                    },
                    {
                        label: "Users",
                        key: "Users"
                    },
                    {
                        label: "Transactions",
                        key: "Transactions"
                    },
                    {
                        label: "Revenue",
                        key: "Revenue"
                    },
                    {
                        label: "ASP",
                        key: "Avg Selling Price"
                    },
                    {
                        label: "AOV",
                        key: "Avg. Order Value"
                    },
                    {
                        label: "Transaction Rate",
                        key: "Transaction Rate"
                    },
                ]
            },
            channelGraphData: {
                xAxis: [],
                seriesData: []
            },
            genderGraphData: {
                xAxis: [],
                seriesData: []
            },
            has_ecom: JSON.parse(localStorage.userData).menu.Ecomm,
            nonEcomFilters: [
                {
                    key: "month",
                    title: "Monthly Totals (Last 6 months)",
                    sortCol: 2,
                    header: [
                        {
                            label: "Month",
                            key: "month"
                        },
                        {
                            label: "Sessions",
                            key: "sessions"
                        },
                        {
                            label: "New Users",
                            key: "newUsers"
                        },
                        {
                            label: "Average Session Duration",
                            key: "Average Session Duration"
                        },
                        {
                            label: "Bounce Rate (%)",
                            key: "bounceRate"
                        },
                        {
                            label: "Page Views",
                            key: "pageviews"
                        },
                    ]
                },
                {
                    key: "channelGrouping",
                    title: "Channel",
                    sortCol: 3,
                    header: [
                        {
                            label: "Channel",
                            key: "channelGrouping"
                        },
                        {
                            label: "Users",
                            key: "users"
                        },
                        {
                            label: "Sessions",
                            key: "sessions"
                        },
                        {
                            label: "New Users",
                            key: "newUsers"
                        },
                        {
                            label: "Average Session Duration",
                            key: "Average Session Duration"
                        },
                        {
                            label: "Bounce Rate (%)",
                            key: "bounceRate"
                        },
                        {
                            label: "Page Views",
                            key: "pageviews"
                        },
                    ]
                },
                {
                    key: "sourceMedium",
                    title: "Paid Channels",
                    sortCol: 3,
                    header: [
                        {
                            label: "Paid Channels",
                            key: "sourceMedium"
                        },
                        {
                            label: "Users",
                            key: "users"
                        },
                        {
                            label: "Sessions",
                            key: "sessions"
                        },
                        {
                            label: "New Users",
                            key: "newUsers"
                        },
                        {
                            label: "Average Session Duration",
                            key: "Average Session Duration"
                        },
                        {
                            label: "Bounce Rate (%)",
                            key: "bounceRate"
                        },
                        {
                            label: "Page Views",
                            key: "pageviews"
                        },
                    ]
                },
                {
                    key: "deviceCategory",
                    title: "Device",
                    sortCol: 3,
                    header: [
                        {
                            label: "Device",
                            key: "deviceCategory"
                        },
                        {
                            label: "Users",
                            key: "users"
                        },
                        {
                            label: "Sessions",
                            key: "sessions"
                        },
                        {
                            label: "New Users",
                            key: "newUsers"
                        },
                        {
                            label: "Average Session Duration",
                            key: "Average Session Duration"
                        },
                        {
                            label: "Bounce Rate (%)",
                            key: "bounceRate"
                        },
                        {
                            label: "Page Views",
                            key: "pageviews"
                        },
                    ]
                },
                {
                    key: "userAgeBracket",
                    title: "Age",
                    sortCol: 3,
                    header: [
                        {
                            label: "Age",
                            key: "Age"
                        },
                        {
                            label: "Users",
                            key: "users"
                        },
                        {
                            label: "Sessions",
                            key: "sessions"
                        },
                        {
                            label: "New Users",
                            key: "newUsers"
                        },
                        {
                            label: "Average Session Duration",
                            key: "Average Session Duration"
                        },
                        {
                            label: "Bounce Rate (%)",
                            key: "bounceRate"
                        },
                        {
                            label: "Page Views",
                            key: "pageviews"
                        },
                    ]
                },
                {
                    key: "userGender",
                    title: "Gender",
                    sortCol: 3,
                    header: [
                        {
                            label: "Gender",
                            key: "Gender"
                        },
                        {
                            label: "Users",
                            key: "users"
                        },
                        {
                            label: "Sessions",
                            key: "sessions"
                        },
                        {
                            label: "New Users",
                            key: "newUsers"
                        },
                        {
                            label: "Average Session Duration",
                            key: "Average Session Duration"
                        },
                        {
                            label: "Bounce Rate (%)",
                            key: "bounceRate"
                        },
                        {
                            label: "Page Views",
                            key: "pageviews"
                        },
                    ]
                },
            ],
            nonEcomLocationTable: {
                key: "",
                title: "Location",
                sortCol: 3,
                header: [
                    {
                        label: "Location",
                        key: ""
                    },
                    {
                        label: "Users",
                        key: "users"
                    },
                    {
                        label: "Sessions",
                        key: "sessions"
                    },
                    {
                        label: "New Users",
                        key: "newUsers"
                    },
                    {
                        label: "Average Session Duration",
                        key: "Average Session Duration"
                    },
                    {
                        label: "Bounce Rate (%)",
                        key: "bounceRate"
                    },
                    {
                        label: "Page Views",
                        key: "pageviews"
                    },
                ]
            },
            lineChartData: []
        }
    },
    mounted(){

    },
    methods: {
        updateComponents() {
            this.componentRender += 1;
        },
        changeLocationTable(location) {
            if(this.has_ecom){
                this.locationTable.key = location.value
                this.locationTable.header[0].key = location.value
                this.selectedLocation = location
            } else if(!this.has_ecom){
                this.nonEcomLocationTable.key = location.value
                this.nonEcomLocationTable.header[0].key = location.value
                this.selectedLocation = location
            }
        },
        processChannelGraph(tableData) {
            let xaxis = [];
            let seriesDataChannel = [];
            if(this.has_ecom){
                seriesDataChannel = [
                    {
                        name: 'Transactions',
                        data: []
                    },
                    {
                        name: 'ASP',
                        data: [],
                        color: "#FCB116"
                    },
                    {
                        name: 'AOV',
                        data: [],
                        color: "#ff8084"
                    },
                ];
            } else if(!this.has_ecom){
                seriesDataChannel = [
                    {
                        name: 'Page Views',
                        data: []
                    },
                    {
                        name: 'Bounces',
                        data: [],
                        color: "#FCB116"
                    },
                    {
                        name: 'Sessions',
                        data: [],
                        color: "#ff8084"
                    },
                ]
            }
            for (let channelItem of tableData) {
                this.channelArr.push(channelItem.channelGrouping);
                xaxis.push(channelItem.channelGrouping);
                if(this.has_ecom){
                    seriesDataChannel[0].data.push(channelItem['Transactions'])
                    seriesDataChannel[1].data.push(channelItem['Avg Selling Price'])
                    seriesDataChannel[2].data.push(channelItem['Avg. Order Value'])
                } else if(!this.has_ecom){
                    seriesDataChannel[0].data.push(channelItem['pageviews'])
                    seriesDataChannel[1].data.push(channelItem['bounces'])
                    seriesDataChannel[2].data.push(channelItem['sessions'])
                }
            }
            this.channelGraphData.xAxis = xaxis;
            this.channelGraphData.seriesData = seriesDataChannel;
        },
        processGenderGraph(tableData) {
            let xaxis = [];
            let seriesDataGender = [];
            if(this.has_ecom){
                seriesDataGender = [
                    {
                        name: 'Transactions',
                        data: []
                    },
                    {
                        name: 'ASP',
                        data: [],
                        color: "#FCB116"
                    },
                    {
                        name: 'AOV',
                        data: [],
                        color: "#ff8084"
                    },
                ];
            } else if(!this.has_ecom){
                seriesDataGender = [
                    {
                        name: 'Page Views',
                        data: []
                    },
                    {
                        name: 'Bounces',
                        data: [],
                        color: "#FCB116"
                    },
                    {
                        name: 'Sessions',
                        data: [],
                        color: "#ff8084"
                    },
                ];
            }
            for (let genderItem of tableData) {
                xaxis.push(genderItem.Gender);
                if(this.has_ecom){
                    seriesDataGender[0].data.push(genderItem['Transactions'])
                    seriesDataGender[1].data.push(genderItem['Avg Selling Price'])
                    seriesDataGender[2].data.push(genderItem['Avg. Order Value'])
                } else if(!this.has_ecom){
                    seriesDataGender[0].data.push(genderItem['pageviews'])
                    seriesDataGender[1].data.push(genderItem['bounces'])
                    seriesDataGender[2].data.push(genderItem['sessions'])
                }
            }
            this.genderGraphData.xAxis = xaxis;
            this.genderGraphData.seriesData = seriesDataGender;
        },
        processMonthLineChart(tableData) {
            // console.log("month data", tableData)
            tableData.map(dataset => {
                this.lineChartData.push({
                    month: dataset.month,
                    newUsers: dataset.newUsers,
                    sessions: dataset.sessions,
                    bounceRate: dataset.bounceRate,
                    pageviews: dataset.pageviews
                })
            })
        }
    }
}