import Vue from 'vue'
import VueRouter from 'vue-router'

// Login
import Login from '../pages/Login/Login.vue'
import Register from '../pages/Register/Register.vue'
// import Dashboard from '../pages/Dashboard/Dashboard.vue'
import BusinessSummary from '../pages/BusinessSummary/BusinessSummary.vue'
import Privacy from '../pages/Privacy/Privacy.vue'

//adwords
import Summary from '../pages/Adwords/Summary.vue'
import GroupData from '../pages/Adwords/GroupData/GroupData.vue'
import CampaignSummary from '../pages/Adwords/Campaign/CampaignSummary.vue'
import AdSummary from '../pages/Adwords/AdSummary/AdSummary.vue'
import DeviceSummary from '../pages/Adwords/DeviceSummary/DeviceSummary.vue'

//Analytics
import AnalyticsSummary from '../pages/Analytics/AnalyticsSummary/AnalyticsSummary.vue'
import EHeatMap from '../pages/Analytics/EHeatMap/EHeatMap.vue'
import WeeklySummary from '../pages/Analytics/WeeklySummary/WeeklySummary.vue'

// Facebook
import FacebookSummary from '../pages/Facebook/FacebookSummary.vue'

//Reports
import AccountReport from '../pages/Reports/AccountReport/AccountReport.vue'
import CampaignReport from '@/pages/Reports/CampaignReport/CampaignReport.vue'
import MediaReport from '@/pages/Reports/MediaReport/MediaReport.vue'
import ClientReport from '@/pages/ClientReport/ClientReport.vue'

// Amazon
import AmazonSearch from '../pages/Amazon/Search/AmazonSearch.vue'
import AmazonDisplay from  '../pages/Amazon/Display/AmazonDisplay.vue'
import AmazonBudgetRule from '../pages/Amazon/BudgetRule/AmazonBudgetRule.vue'
import AmazonReport from '../pages/Amazon/Report/AmazonReport.vue'
import AmazonSilver from '@/pages/Amazon/AmazonSilver/AmazonSilver.vue'
import AMS from '@/pages/Amazon/AMS/AMS.vue'
//Flipkart
import FlipkartSearch from '@/pages/Flipkart/Search/FlipkartSearch.vue'
import FlipkartDisplay from '@/pages/Flipkart/Display/FlipkartDisplay.vue'
import FlipkartReport from '@/pages/Flipkart/Report/FlipkartReport.vue'

// Settings
import UserSettings from '@/pages/Settings/UserSettings/UserSettings.vue'

//custom reports
import AmazonAccountSummary from '@/pages/Amazon/AccountSummary/AmazonAccountSummary.vue'

// Recovery Password
import RecoveryPassword from '@/pages/RecoveryPassword/RecoveryPassword.vue'

// PPT 
import PPT from '@/pages/PPT/PPT.vue'

//Programmetic
import Programatic from '@/pages/Programmetic/Programatic.vue'

// test performance report 
import PerformanceSummary from '@/pages/PerformanceSummary/PerformanceSummary.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name:'Register',
    component: Register

  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy
  },
  // {
  //   path: '/dash',
  //   name: 'Dashboard',
  //   component: Dashboard
  // },
  {
    path: '/recovery/:id',
    name: 'Recovery Password',
    component: RecoveryPassword
  },
  //Business Summary
  {
    path: '/business-summary',
    name: 'Business Summary',
    auth: false,
    component: BusinessSummary
  },
  {
    path: '/performance-summary',
    name: 'Performance Summary',
    auth: false,
    component: PerformanceSummary
  },
  //Adwords
  {
    path: '/summary',
    name: 'Adwords Summary',
    component:Summary
  },
  {
    path: '/group-data',
    name: 'Group Data',
    component: GroupData
  },
  {
    path: '/campaign-summary',
    name: 'Campaign Summary',
    component: CampaignSummary
  },
  {
    path: '/adword-summary',
    name: 'Adword Summary',
    component: AdSummary
  },
  {
    path: '/device-summary',
    name: 'Device Summary',
    component: DeviceSummary
  },
  //Analytics
  {
    path: '/analytics-summary',
    name: 'Analytics Summary',
    component: AnalyticsSummary
  },
  {
    path: '/heatmap',
    name: 'E-commerce Heatmap',
    component: EHeatMap
  },
  {
    path: '/weekly-summary',
    name: 'Ecommerce Weekly Summary',
    component: WeeklySummary
  },
  //facebook
  {
    path: '/facebook-summary',
    name: 'Facebook Summary',
    component: FacebookSummary
  },
  //reports
  {
    path: '/account-report',
    name: 'Account Report',
    component: AccountReport
  },
  {
    path: '/client-report',
    name: 'Account Summary',
    component: ClientReport
  },
  {
    path: '/campaign-report',
    name: 'Campaign Report',
    component: CampaignReport
  },
  {
    path: '/media-report',
    name: 'Media Report',
    component: MediaReport
  },
  // Amazon 
  {
    path: '/amazon-search',
    name: 'Amazon Search',
    component: AmazonSearch
  },
  {
    path: '/amazon-display',
    name: 'Amazon Display',
    component: AmazonDisplay
  },
  {
    path: '/amazon-budget-rule',
    name: 'Amazon Budget Rule',
    component: AmazonBudgetRule
  },
  {
    path: '/amazon-report',
    name: 'Amazon Report',
    component: AmazonReport
  },

  {
    path: '/amazon-silver',
    name: 'Amazon Silver',
    component: AmazonSilver
  },
  {
    path: '/ams',
    name: 'AMS',
    component: AMS
  },

  {
    path: '/amazon-account-summary',
    name: 'Account Summary',
    component: AmazonAccountSummary
  },

  //Flipkart 
  {
    path: '/flipkart-search',
    name: 'Flipkart Search',
    component: FlipkartSearch
  },
  {
    path: '/flipkart-display',
    name: 'Flipkart Display',
    component: FlipkartDisplay
  },
  {
    path: '/flipkart-report',
    name: 'Flipkart Report',
    component: FlipkartReport
  },

  // Settings
  {
    path: '/setting-user',
    name: 'User Settings',
    component: UserSettings
  },
  //PPT
  {
    path: '/PPT',
    name: 'PPT',
    component: PPT
  },
  //Programmatic
  {
    path: '/programmatic',
    name: 'Programmatic',
    component: Programatic
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
