<template lang="pug">
div.w-full
    highcharts(:options="chartData")
</template>

<!-- props format :-
 data: {
    name: string,
    seriesData: [{
        name: string,
        value: string,
        level_id: any (optional)
    },...]
 } -->

<script>
import Highcharts from 'highcharts';
export default {
    name: 'drillPieChart',
    props: ['data'],
    data() {
        return {
            chartData: {},
            color_codes: [
                "#ffa2a4",
                "#899efc",
                "#eaa9fb",
                "#ffd7d8",
                "#ddc7f8",
                "#ff8392",
                "#dbd0bf",
                "#ebefbf",
                "#d7edbb",
                "#9ddec5",
                "#ffd0b1",
                "#666872",
                "#8C9BFF",
            ],
            pieWidth: null
        }
    },
    watch: {
        data: {
            handler() {
                this.initiate();
            },
            deep: true
        }
    },

    mounted() {
        this.initiate();
    },

    methods: {
        pieSize(){
            if(window.screen.width < 600){
                return 120
            } else return;
        },
        dataLabelFont(){
            if(window.screen.width < 600){
                return 7
            } else return;
        },
        initiate() {
            if(!this.data.seriesData.length) return false

            const seriesData = []

            // looping over seriesData
            this.data.seriesData.forEach((element) => {
                let levelId;
                if(element.level_id) levelId = element.level_id
                else levelId = null;
                seriesData.push({
                    name: element.name,
                    y: element.value,
                    level_id: levelId
                })
            });

            Highcharts.setOptions({
                lang: {
                    decimalPoint: '.',
                    thousandsSep: ','
                }
            });

            const that = this
            this.chartData = {
                chart: {
                    type: 'pie',
                },
                title: {
                    text: ''
                },
                credits: {
                    enabled: false
                },
                accessibility: {
                    announceNewData: {
                        enabled: true
                    },
                    point: {
                        valueSuffix: '%'
                    }
                },
                plotOptions: {
                    pie: {
                        size: this.pieSize(),
                        dataLabels: {
                            style: {
                                fontSize: this.dataLabelFont()
                            }
                        }
                    },
                    series: {
                        dataLabels: {
                            enabled: true,
                            // formatting the labels
                            formatter: function(){
                                let label = '';
                                if(this.point.name.includes('@')) label = this.point.name.slice(0, this.point.name.indexOf("@")) + ": ";
                                if(this.point.name.length > 12) label = this.point.name.slice(0, 5) + "..." + this.point.name.slice(-5) + ": ";
                                else label = this.point.name + ': ';

                                if (that.data.name.toLowerCase().includes('rs')) label += 'Rs '
                                label += this.point.y.toLocaleString("en-US")
                                if (that.data.name.toLowerCase().includes('%')) label += '%'
                                return label
                            }
                        },
                        colors: this.color_codes
                    }
                },
                tooltip: {
                    headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                    pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
                },
                series: [{
                    name: this.data.name,
                    colorByPoint: true,
                    innerSize: "50%",

                    data: seriesData,
                    events: {
                        click: (event) => {
                            this.$emit('section-click', event.point.level_id);
                        }
                    }
                }],
            }
        },
    },

}
</script>