import { render, staticRenderFns } from "./PPT.vue?vue&type=template&id=abc7760e&scoped=true&lang=pug&"
import script from "./PPT.js?vue&type=script&lang=js&"
export * from "./PPT.js?vue&type=script&lang=js&"
import style0 from "./PPT.vue?vue&type=style&index=0&id=abc7760e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "abc7760e",
  null
  
)

export default component.exports