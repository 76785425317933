import { render, staticRenderFns } from "./Programatic.vue?vue&type=template&id=8023ae10&scoped=true&lang=pug&"
import script from "./Programatic.js?vue&type=script&lang=js&"
export * from "./Programatic.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8023ae10",
  null
  
)

export default component.exports