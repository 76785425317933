import { render, staticRenderFns } from "./AmazonBudgetRule.vue?vue&type=template&id=6273b7e2&scoped=true&lang=pug&"
import script from "./AmazonBudgetRule.js?vue&type=script&lang=js&"
export * from "./AmazonBudgetRule.js?vue&type=script&lang=js&"
import style0 from "./AmazonBudgetRule.sass?vue&type=style&index=0&id=6273b7e2&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6273b7e2",
  null
  
)

export default component.exports