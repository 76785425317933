import axios from 'axios'
import lineChart from '@/components/charts/lineChart.vue'
import moment from 'moment'
import Loader from '@/components/Loader/Loader.vue'

export default {
  name: 'PerformanceSummary',
  components: { lineChart, Loader },
  data() {
    return {
      moment: moment,
      isCompare: false,
      interval: 'day',
      widthMapper: {
        'full': 'ui sixteen wide column',
        'half': 'ui eight wide column'
      },
      dateRange: {
        startDate: moment().subtract(1, 'months'),
        endDate: moment()
      },
      prevDateRange: {
        startDate: moment().subtract(2, 'months'),
        endDate: moment().subtract(1, 'months')
      },
      allTablesData: [],
      regex: /^(https?|http):\/\/[^\s/$.?#].[^\s]*$/i,
      graphArrayFirst: [],
      graphArraySecond: [],
      specialChar: '',
      optionData: [],
      firstGraphSelectedList: [],
      secondGraphSelectedList: [],
      loader: ''
    }
  },

  mounted() {
    self = this
    self.getTableData()
  },

  methods: {
    downloadAccountReport() {
      self = this
      self.loader = true
      let url = "/api/report/downloadaccountreport"
      let payload = {
        data: self.allTablesData
      }
      axios.post(url, payload).then(res => {
        self.loader = false
        window.location = res.data.data
      }).catch(err => {
        console.log("err")
        self.loader = false
      })
    },

    checkLink(value) {
      this.regex.test(value)
    },

    getTableData() {
      self = this
      self.loader = true
      self.allTablesData = []
      var search = require("@/assets/Icons_SVG/Search.svg");
      let url = "/api/summary/performance/"
      let payload = {
        'start_date': moment(self.dateRange.startDate).format('YYYY-MM-DD'),
        'end_date': moment(self.dateRange.endDate).format('YYYY-MM-DD'),
        "client": 'test'
      }
      axios.post(url, payload).then((response) => {
        self.loader = false
        self.allTablesData = response.data.data
        this.$nextTick(() => {
          for (let [i, d] of self.allTablesData.entries()) {
            let temp = Object.keys(d.graph[0])
            self.firstGraphSelectedList.push({
              selectedGraph: temp[1]
            })
            self.secondGraphSelectedList.push({
              selectedGraph: temp[2]
            })
            $('#table_dynamic_report_' + i).dataTable()
            $('#table_dynamic_report_' + i).DataTable().destroy()
            $('#table_dynamic_report_' + i).DataTable({
              initComplete: function (settings, json) {
                $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                $('#table_dynamic_report_' + i).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
              },
              lengthChange: true,
              info: false,
              sorting: [[0, "asc"]],
              language: {
                search: "", searchPlaceholder: "Search...", paginate: {
                  first: "",
                  previous: "<",
                  next: ">",
                  last: "",
                },
                lengthMenu: "<h5>Show entries</h5> _MENU_"
              },
            })
            $('#table_dynamic_report_' + i + "_filter > label").append(
              `<div class='search_icon'><img src='${search}' /></div>`
            );
          }
          self.processFirstGraphData()
          self.processSecondGraphData()
        });
      }).catch(err => {
        console.log(err)
      })
    },

    processFirstGraphData() {
      self = this
      self.graphArrayFirst = []
      for (let [i, data] of self.allTablesData.entries()) {
        if (data.graph) {
          let tempKey = []
          for (let key in data.graph[0]) {
            if (key != 'Date')
              tempKey.push(key)
          }
          tempKey.reverse()
          self.optionData.push(tempKey)
          let oneSectionGraph = []
          let graphData = {
            xAxis: [],
            seriesData: [{
              name: '',
              data: []
            }],
            prefix: '',
            suffix: ''
          }
          graphData.seriesData[0].name = self.firstGraphSelectedList[i].selectedGraph
          for (let data2 of data.graph) {
            graphData.seriesData[0].data.push(data2[self.firstGraphSelectedList[i].selectedGraph].value)
            graphData.xAxis.push(data2.Date.value)
            graphData.prefix = data2[self.firstGraphSelectedList[i].selectedGraph].prefix
            graphData.suffix = data2[self.firstGraphSelectedList[i].selectedGraph].suffix
          }
          oneSectionGraph.push(graphData)
          self.graphArrayFirst.push(oneSectionGraph)
        }
        else {
          oneSectionGraph = []
          self.graphArrayFirst.push(oneSectionGraph)
        }
      }
    },

    processSecondGraphData() {
      self = this
      self.graphArraySecond = []
      for (let [i, data] of self.allTablesData.entries()) {
        if (data.graph) {
          let tempKey = []
          for (let key in data.graph[0]) {
            if (key != 'Date') {
              tempKey.push(key)
            }
          }
          tempKey.reverse()
          self.optionData.push(tempKey)
          let oneSectionGraph = []
          let graphData = {
            xAxis: [],
            seriesData: [{
              name: '',
              data: []
            }],
            prefix: '',
            suffix: ''
          }
          graphData.seriesData[0].name = self.secondGraphSelectedList[i].selectedGraph
          for (let data2 of data.graph) {
            graphData.seriesData[0].data.push(data2[self.secondGraphSelectedList[i].selectedGraph].value)
            graphData.xAxis.push(data2.Date.value)
            graphData.prefix = data2[self.secondGraphSelectedList[i].selectedGraph].prefix
            graphData.suffix = data2[self.secondGraphSelectedList[i].selectedGraph].suffix
          }
          oneSectionGraph.push(graphData)
          self.graphArraySecond.push(oneSectionGraph)
        }
        else {
          oneSectionGraph = []
          self.graphArraySecond.push(oneSectionGraph)
        }
      }
    }
  }

}