import { render, staticRenderFns } from "./AmazonSearch.vue?vue&type=template&id=158aea7b&scoped=true&lang=pug&"
import script from "./AmazonSearch.js?vue&type=script&lang=js&"
export * from "./AmazonSearch.js?vue&type=script&lang=js&"
import style0 from "./AmazonSearch.sass?vue&type=style&index=0&id=158aea7b&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "158aea7b",
  null
  
)

export default component.exports