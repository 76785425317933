import axios from 'axios'
import Toast from '@/components/Toast/Toast.vue'
export default
    name:'Login'
    components:{
        Toast
    },
    data:()->
        username: ''
        password: ''
        userData: ''
        isForgetPassword:false
        recoveryEmail:''
        flagError: false
    mounted:()->
        this.init();
    methods:
        init:()->
            this.dataAPIs()

        dataAPIs:()->    

        login:()->
            self = this
            formData = new FormData()
            url = "/api/login/"
            formData.append('username',self.username)    
            formData.append('password',self.password)    
            axios.post(url,formData).then((res) ->
                axios.defaults.headers.common['token'] = res.data.data.token
                localStorage.userData = JSON.stringify(res.data)
                if res.data.weavr_admin_access
                    self.$toast.warning("Login on this link https://admin.weavr.online/#/ for admin access", {
                        position: "bottom-center",
                        timeout: false,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        rtl: false
                     });
                self.$nextTick ->
                    self.$store.dispatch('setUser')
                    self.$store.dispatch('setMenu')
                    self.$router.push(this.$store.state.menu[0].submenuItems[0].link);
                
            ).catch (err) ->
                self.flagError=true
                console.log(self.flagError)
                    
        toggleForgetModal:()->
            this.isForgetPassword = !this.isForgetPassword
        
        passwordRecovery:()->
            self = this
            url = "/api/users/recovery/"
            payload = {
                "email_id": this.recoveryEmail
            }
            axios.post(url, payload)
            .then((res)-> 
                self.$toast.success("Recovery link has been sent to your email", {
                        position: "bottom-center",
                        timeout: 3000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        rtl: false
                     });
                self.isForgetPassword = false
            )
            .catch((err)-> 
               self.$toast.error("Please enter correct email id", {
                        position: "bottom-center",
                        timeout: 3000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        rtl: false
                     });
            )

