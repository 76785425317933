



  
export default 
  name: 'stackChart'
  props: [ 'data' ]
  data:() ->
    chartData: {}
  watch:
    data:
      deep: true
      handler:() ->
        @initiate()  
      
  mounted:() ->
    this.initiate()
    
  methods:
    labelFontSize:()->
      if window.screen.width < 600
        return 9
      else return;

    legendFontSize:()->
      if window.screen.width < 600
        return "2vw"
      else return "0.8vw"
    
    legendItemDistance:()->
      if window.screen.width < 600
        return 0
      else return 20

    initiate:() ->
      self = this
      if !self.data.seriesData.length
        return
      self.chartData =
        chart: 
          type: 'column'
        credits:
          enabled: false
        legend:
          itemStyle:  
            fontSize: self.legendFontSize()
          enabled: true
          itemDistance: self.legendItemDistance()
        title: 
          text: ''
        xAxis: 
          categories: self.data.xAxis 
          title: text: 'Duration'
          labels:
            style:
              fontSize: self.labelFontSize()
        yAxis:
          min: 0
          title: 'hello'
        tooltip:
          formatter: ->
            i = 0
            mytooltip = undefined
            rs = "Rs "
            mytooltip = '<b>' + @points[0].key + '</b>'
            while i < @points.length
              mytooltip += '<br/><span style="color:' + @points[i].series.color + '">●</span> '
              mytooltip += @points[i].series.name
              mytooltip += ' : <b>' + rs + @points[i].y.toLocaleString("en-US") + ' (' 
              mytooltip += ((@points[i].y/@points[0].total)*100).toFixed(2) + '%)</b>'
              i++
            mytooltip += '<br/><b>Total : ' +  rs + @points[0].total.toLocaleString("en-US") + '</b>'
            mytooltip
          borderWidth: 0
          shadow: {
            color: "#d6d6d6",
            opacity: 0.1,
            width: 10,
          }
          borderRadius: 6
          backgroundColor: "#ffffff"
          shared: true
          shape: 'rect',
          borderWidth: 0,
          shadow: {
            color: "#d6d6d6",
            opacity: 0.1,
            width: 10,
          },
          borderRadius: 6,
          backgroundColor: "#ffffff",
          headerFormat: '',
          style: {
            fontSize: '14px'
          },
        plotOptions: 
          column: 
            stacking: 'normal'
            borderWidth : 0
        series: self.data.seriesData
