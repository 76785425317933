import axios from 'axios'
import moment from 'moment'
import Loader from '@/components/Loader/Loader.vue'
import Header from '@/components/Header/Header.vue'
import lineChart from '@/components/charts/lineChart.vue'
import drillPieChart from '@/components/charts/drillPieChart.vue'


// api :-
// https://weavr.online/api/summary/campaigns/?start_date=2023-01-22&end_date=2023-02-21
// https://weavr.online/api/comment/
// https://weavr.online/api/summary/aggrigate/?start_date=2023-02-01&end_date=2023-02-21
// https://weavr.online/api/summary/aggrigate/?start_date=2023-01-11&end_date=2023-01-31

export default{
    name: 'Summary',
    components: {
        Header,
        Loader,
        lineChart,
        drillPieChart
    },
    data(){
        return {
            dateRange: {
                startDate: moment().subtract(1, 'week'),
                endDate: moment()
            },
            dataLoader: false,
            campaignData: [],
            selectedCampaign: {name: 'ALL'},
            summaryCardData: [],
            graphData: [],
            costData: [],
            dropdownChartData: {
                xAxis: [],
                seriesData: [],
                ylabel: ''
            },
            impressionChartData: {
                xAxis: [],
                seriesData: [],
                ylabel: ''
            },
            clickChartData: {
                xAxis: [],
                seriesData: [],
                ylabel: ''
            },
            budgetChartData: {
                xAxis: [],
                seriesData: [],
                ylabel: ''
            },
            annualBudgetChartData: {
                xAxis: [],
                seriesData: [],
                ylabel: ''
            },
            pieChartData: {
                name: '',
                seriesData: []
            },
            chartDropdown: {
                options: [
                    {id: 'cost', text: 'Cost'},
                    {id: 'ctr', text: 'CTR'},
                    {id: 'cpm', text: 'CPM'},
                    {id: 'cpc', text: 'CPC'},
                    {id: 'conversions', text: 'Conversions'},
                    {id: 'cost_conversion', text: 'Cost per Conversion'}
                ],
                value: {id: 'cpc', text: 'CPC'}
            }
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        init(){
            this.formatDateRange();
            this.dataAPIs();
        },
        dataAPIs(){
            this.getAggrigateData();
            this.getCampaignData();
        },
        formatDateRange(){
            this.dateRange.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            this.dateRange.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD');
        },
        formatDates() {
            this.formatDateRange();
            this.dataAPIs()
        },
        decodeHTML(html){
            let txt = document.createElement('textarea');
	        txt.innerHTML = html;
	        return txt.value; // decoder for HTML-encoded strings.
        },
        processDropdownGraph(){
            let dataPoints = []
            const xAxis = []
            const seriesData = []
            this.graphData.forEach((data)=>{
                dataPoints.push(data[this.chartDropdown.value.id])
                xAxis.push(moment(data.date).format('DD-MMM'))
            });
            dataPoints = dataPoints.map((data)=> Math.round(data * 100) / 100)
            seriesData.push({name: 'Adword', data: dataPoints})

            this.dropdownChartData = {
                xAxis: xAxis,
                seriesData: seriesData,
                ylabel: this.chartDropdown.value.text
            }
        },
        processImpressionGraph(){
            let dataPoints = []
            const xAxis = []
            const seriesData = []
            this.graphData.forEach((data)=>{
                dataPoints.push(data.impressions)
                xAxis.push(moment(data.date).format('DD-MMM'))
            });
            dataPoints = dataPoints.map((data)=> Math.round(data * 100) / 100)
            seriesData.push({name: 'Adword', data: dataPoints})

            this.impressionChartData = {
                xAxis: xAxis,
                seriesData: seriesData,
                ylabel: 'Impressions'
            }
        },
        processClickGraph(){
            let dataPoints = []
            const xAxis = []
            const seriesData = []
            this.graphData.forEach((data)=>{
                dataPoints.push(data.clicks)
                xAxis.push(moment(data.date).format('DD-MMM'))
            });
            dataPoints = dataPoints.map((data)=> Math.round(data * 100) / 100)
            seriesData.push({name: 'Adword', data: dataPoints})

            this.clickChartData = {
                xAxis: xAxis,
                seriesData: seriesData,
                ylabel: 'Clicks'
            }
        },
        processBudgetGraph(){
            let dataPoints = []
            const xAxis = []
            const seriesData = []
            this.graphData.forEach((data)=>{
                dataPoints.push(data.cost)
                xAxis.push(moment(data.date).format('DD-MMM'))
            });
            dataPoints = dataPoints.map((data)=> Math.round(data * 100) / 100)
            seriesData.push({name: 'Adword', data: dataPoints})

            this.budgetChartData = {
                xAxis: xAxis,
                seriesData: seriesData,
                ylabel: 'Cost'
            }
        },
        processAnnualBudgetGraph(){
            let dataPoints = []
            const xAxis = []
            const seriesData = []
            this.costData.forEach((data)=>{
                dataPoints.push(data.cost)
                xAxis.push(moment(data.date).format('MMM-YY'))
            });
            dataPoints = dataPoints.map((data)=> Math.round(data * 100) / 100)
            seriesData.push({name: 'Adword', data: dataPoints})

            this.annualBudgetChartData = {
                xAxis: xAxis,
                seriesData: seriesData,
                ylabel: 'Cost'
            }
        },
        processPieGraph(){
            const allFields = ['impressions', 'clicks', 'visits', 'conversions']
            const seriesData = []
            this.summaryCardData.forEach((data)=>{
                if( allFields.includes(data.label.toLowerCase()) ) {
                    seriesData.push({name: data.label, value: data.int_value})
                }
            });

            this.pieChartData = {
                name: 'Adword',
                seriesData: seriesData
            }
            console.log(this.pieChartData);
        },
        getCampaignData(){
            this.dataLoader = true;
            let url = '/api/summary/campaigns/?'
            url += `start_date=${this.dateRange.startDate}&end_date=${this.dateRange.endDate}`
            if(this.selectedCampaign.name !== 'ALL') url += `&camp_id=${this.selectedCampaign.id}&camp_name=${this.selectedCampaign.name}`

            axios.get(url).then((res)=>{
                const resData = res.data
                resData.camps.push({name: 'ALL'})
                this.campaignData = resData.camps
                console.log(this.campaignData);
            }).catch(err => console.log(err))
        },
        getAggrigateData(){
            let url = '/api/summary/aggrigate/?'
            url += `start_date=${this.dateRange.startDate}&end_date=${this.dateRange.endDate}`
            if(this.selectedCampaign.name !== 'ALL') url += `&camp_id=${this.selectedCampaign.id}&camp_name=${this.selectedCampaign.name}`

            this.dataLoader = true;
            axios.get(url).then((response)=>{
                const resData = response.data;
                this.summaryCardData = resData.summary;
                this.summaryCardData.forEach(data => {
                    data.label = data.label
                    data.int_value = data.value
                    data.value = data.display
                    if (data.prefix) data.value = this.decodeHTML(data.prefix) + " " + data.value;
                    if (data.suffix) data.value = data.value + data.suffix;
                });
                this.graphData = resData.graph_data;
                this.costData = resData.cost_data;
                this.$nextTick(()=>{
                    this.processDropdownGraph();
                    this.processPieGraph();
                    this.processImpressionGraph();
                    this.processClickGraph();
                    this.processBudgetGraph();
                    this.processAnnualBudgetGraph();
                    this.dataLoader = false;
                });
            }).catch((err)=>{
                console.log(err);
                this.dataLoader = false;
            });
        }
    },

}