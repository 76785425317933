import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import moment from 'moment'

const store = new Vuex.Store({
    state: {
        userData: {
            id: "",
            username: "",
            has_ecomm: false,
            year: '01-01',
            clients: [],
            current: ""
        },
        menu: []
    },
    mutations: {
        UNSET_MENU(state) {
            localStorage.removeItem('userData')
            state.menu = []
            state.userData = { id: '', username: '' }
        },
        SET_USER(state) {
            state.userData = {
                username: JSON.parse(localStorage.userData).data.username,
                id: JSON.parse(localStorage.userData).data.user_id,
                started: moment(JSON.parse(localStorage.userData).started),
                clients: JSON.parse(localStorage.userData).clients.clients,
                current: JSON.parse(localStorage.userData).clients.current,
                internal: JSON.parse(localStorage.userData).internal,
                meeting: JSON.parse(localStorage.userData).meeting,
                monitoring: JSON.parse(localStorage.userData).monitoring,
                fb_access: JSON.parse(localStorage.userData).fb_access,
                screen_access: JSON.parse(localStorage.userData).screen_access,
                weavr_admin_access: JSON.parse(localStorage.userData).weavr_admin_access,
                datastudio_access: JSON.parse(localStorage.userData).datastudio_access,
                googledemo_access: JSON.parse(localStorage.userData).googledemo_access,

            }
        },
        SET_MENU(state) {
            if (!state.userData.id) return;
            try {
                var usermenu = JSON.parse(localStorage.userData).menu;
            }
            catch (e) {
                usermenu = {}
            }

            state.userData.year = moment(usermenu.financial_year, 'MM-DD')

            let Summary = {
                title: "Summary",
                isExpanded: false,
                submenuItems: [
                    {
                        item: "Business Summary",
                        link: "/business-summary"
                    },
                    // {
                    //     item: "Performance Summary",
                    //     link: "/performance-summary"
                    // }
                ]
            }

            let menu = [Summary];

            if (usermenu.GA == false) {
                menu = [];
            }

            let Facebook = {
                title: "Facebook",
                isExpanded: false,
                submenuItems: [
                    {
                        item: "Facebook Summary",
                        link: "/facebook-summary"
                    },
                ]
            }
            let Ads = {
                title: "Ads",
                isExpanded: false,
                submenuItems: [
                    {
                        item: 'Summary',
                        link: '/summary'
                    },
                    {
                        item: 'Group Data',
                        link: '/group-data'
                    },
                    {
                        item: 'Campaign Summary',
                        link: '/campaign-summary'
                    },
                    {
                        item: 'Adword Summary',
                        link: '/adword-summary'
                    },
                    {
                        item: 'Device Summary',
                        link: '/device-summary'
                    }
                ]
            }

            let Analytics = {
                title: "Analytics",
                isExpanded: false,
                submenuItems: []
            }

            let Reports = {
                title: "Reports",
                isExpanded: false,
                submenuItems: []
            }

            state.userData.has_ecomm = usermenu.Ecomm;
            state.userData.week_start = usermenu.week_start;
            state.userData.week_end = usermenu.week_end;
            state.userData.has_video = usermenu.video;
            state.userData.has_ga = usermenu.GA;
            state.userData.has_kama = usermenu.kama;
            state.userData.fb_utm = usermenu.FBUTM;
            state.userData.programmatic = usermenu.programmatic;

            if(usermenu.programmatic){
                Reports.submenuItems.push(
                    {
                        item: 'Programmatic Report',
                        link: '/programmatic'
                    }
                )
            }

            if (usermenu.Ecomm) {
                Analytics.submenuItems = Analytics.submenuItems.concat([
                    {
                        item: 'Analytics Summary',
                        link: '/analytics-summary'
                    },
                    {
                        item: 'Heatmap',
                        link: '/heatmap'
                    },
                    {
                        item: 'Weekly Summary',
                        link: '/weekly-summary'
                    }
                ]);
            } else {
                Analytics.submenuItems = Analytics.submenuItems.concat([
                    {
                        item: 'Analytics Summary',
                        link: '/analytics-summary'
                    },
                    {
                        item: 'Heatmap',
                        link: '/heatmap'
                    },
                ])
            }
            if (usermenu.ADWORD) {
                Reports.submenuItems.push(
                    {
                        item: 'Campaign Report',
                        link: '/campaign-report'
                    },
                )
            }

            if (usermenu.asics && !state.userData.googledemo_access) {
                Reports.submenuItems.push(
                    {
                        item: 'Media Report',
                        link: '/media-report'
                    })
            }

            if (usermenu.kama) {
                Ads.submenuItems.push(
                    {
                        item: 'Account Report',
                        link: '/account-report'
                    },
                )
            }

            Reports.submenuItems.push(
                {
                    item: 'Account Report',
                    link: '/account-report'
                })

            let Amazon = {
                title: "Amazon",
                isExpanded: false,
                submenuItems: [
                    {
                        item: 'Amazon Search',
                        link: '/amazon-search'
                    },
                    {
                        item: 'Amazon Budget Rule',
                        link: '/amazon-budget-rule'
                    },
                    {
                        item: 'Amazon Silver',
                        link: '/amazon-silver'
                    },
                    {
                        item: 'AMS',
                        link: '/ams'
                    },

                ]
            }

            if (usermenu.Amazon_Display) {
                Amazon.submenuItems.push(
                    {
                        item: 'Amazon Display',
                        link: '/amazon-display'
                    },
                )
            }
            if (usermenu.AMD_Report) {
                Amazon.submenuItems.push(
                    {
                        item: 'Amazon Report',
                        link: '/amazon-report'
                    }
                )
            }
            if (state.userData.current === "RR Kabel" || state.userData.current ===
                "AMD" || state.userData.current =="Bosch Power Tools") {
                Amazon.submenuItems.push(
                    {
                        item: 'Account Summary',
                        link: '/amazon-account-summary'
                    }
                )
            }
            if (state.userData.current === "Tasva") {
                Reports.submenuItems.push(
                    {
                        item: 'Account Summary',
                        link: '/client-report'
                    }
                )
            }

            let Flipkart = {
                title: "Flipkart",
                isExpanded: false,
                submenuItems: []
            }

            if (usermenu.Flipkart_Search) {
                Flipkart.submenuItems.push(
                    {
                        item: 'Flipkart Search',
                        link: '/flipkart-search'
                    },
                )
            }
            if (usermenu.Flipkart_Display) {
                Flipkart.submenuItems.push(
                    {
                        item: 'Flipkart Display',
                        link: '/flipkart-display'
                    },
                )
            }
            if (usermenu.AMD_Report) {
                Flipkart.submenuItems.push(
                    {
                        item: 'Flipkart Report',
                        link: '/flipkart-report'
                    }
                )
            }


            if (usermenu.ADWORD) menu.push(Ads)
            if (usermenu.GA) menu.push(Analytics);
            //      if(usermenu.SENTIMENT) menu.push("Sentiment")
            if (usermenu.FB) menu.push(Facebook);
            // if (usermenu.Linkedin) menu.push(Linkedin)
            if (usermenu.Amazon) menu.push(Amazon)
            if (Flipkart.submenuItems.length) menu.push(Flipkart)
            if (Reports.submenuItems.length) menu.push(Reports)

            // if (state.user.meeting)
            //     menu.push(meeting);
            // if (state.user.monitoring)
            //     menu.push(monitoring);
            // }

            // if (state.user.googledemo_access) {
            //     menu = []
            //     menu.push({ title: "Summary", name: "Summary", googledemo: true })
            //     menu.push({ title: "Campaign Summary", name: "adwords.byCampaign", googledemo: true })
            //     menu.push({ title: "Adword Summary", name: "adwords.Detailed", googledemo: true })
            //     menu.push({ title: "Device Summary", name: "adwords.devicesummary", googledemo: true })
            // }
            state.menu = menu;
            // setTimeout(function () {
            //     $('.main-menu .ui.dropdown.item').click(function (e) {
            //         e.stopPropagation();
            //         $(this).siblings().children('.menu').hide();
            //         $(this).children('.menu').toggle();
            //     })
            //     $('body').click(function () {
            //         $('.main-menu .ui.dropdown.item').children('.menu').hide();
            //     })
            //     $('body').click(function () {
            //         $('.main-menu .ui.dropdown.item').children('.menu').hide();
            //     })

            // }, 1000);
        },
        SET_EXPANSION(state, menuTitle) {
            state.menu.map(item => {
                if (item.title === menuTitle) {
                    item.isExpanded = !item.isExpanded
                }
            })
        }
    },
    actions: {
        setMenu({ commit }) {
            commit('SET_MENU')
        },
        unsetMenu({ commit }) {
            commit('UNSET_MENU')

        },
        setUser({ commit }) {
            commit('SET_USER')
        },
        toggleExpansion({ commit }, menuTitle) {
            commit('SET_EXPANSION', menuTitle)
        }
    }
})

export default store;