<template lang="pug">
div
    div(class="flex justify-between")
        h2(class="2xl:text-xl xl:text-base text-primary_brand font-semibold") Order Progress in last 12 months
        div(class="flex justify-end items-center mb-4")
            div(class="customMultiselect 2xl:w-48 xl:w-36 mr-4")
                multiselect(v-model="selectedDimension" :options="dimension" :searchable="false"
                    :close-on-select="true" :show-labels="false" :multiple="false" :allow-empty="false" track-by="value"
                    label="label" :preselectFirst="true" @select="componentRender += 1")
            div(class="2xl:text-xl xl:text-base text-primary_brand font-medium -mt-2") vs
            div(class="flex customMultiselect 2xl:w-48 xl:w-36 ml-4")
                multiselect(v-model="selectedMetrics" :options="metrics" :searchable="false" :close-on-select="true"
                    :show-labels="false" :multiple="false" :allow-empty="false" track-by="value" label="label"
                    :preselectFirst="true" @select="componentRender += 1")
    div(v-if="loader")
        Loader 
    div(v-if="this.heatmapTable.data.length")
        table(id="heatmap_table")
            thead
                tr
                    th
                        div(class="diagonalWrapper")
                            span(class="belowdiagonalLine") {{ selectedDimension.label }}
                            span(class="abovediagonalLine") Month
                        hr(:class="selectedDimension.value === 'city' ? 'cityLine' : 'diagonalLine'")
                    th(v-for="th of heatmapTable.header" v-if="th !== ''") {{ th }}

            tbody
                tr(v-for="td of heatmapTable.data.slice(0, -1)")
                    td {{ td[0] }}
                    td(v-for="tdData of td.slice(1)"
                        :class="{ 'bg-red-40': tdData.rank == 1, 'bg-pink-40': tdData.rank == 2, 'bg-blue-40': tdData.rank == 3, 'bg-olive-40': tdData.rank == 4, 'bg-accent_color-40': tdData.rank == 5 }") {{ numberFormat(tdData.value) }}
            tfoot
                tr(v-for="tf of heatmapTable.data.slice(-1)")
                    th {{ tf[0] }}
                    th(v-for="tfooter of tf.slice(1, -1)") {{ numberFormat(tfooter) }}
                    th(v-for="tfooterlast of tf.slice(-1)") {{ tfooterlast }}
</template>
<script>
import axios from 'axios';
import Loader from '@/components/Loader/Loader.vue';
export default {
    name: "AnalyticsHeatmap",
    components: { Loader },
    emits: ['start-loading', 'stop-loading'],
    data() {
        return {
            componentRender: 0,
            loader: false,
            dimension: [
                { label: "Channel", value: "channelGrouping" },
                { label: "City", value: "city" }
            ],
            selectedDimension: "",
            metrics: [
                { label: "Revenue", value: "revenue" },
                { label: "Transactions", value: "transactions" }
            ],
            selectedMetrics: "",
            heatmapTable: {
                header: [],
                data: []
            },
        }
    },
    mounted() {
        this.getHeatmapData();
        this.componentRender = 0;
    },
    watch: {
        componentRender() {
            this.loader = true;
            this.heatmapTable.data = [];
            $('#heatmap_table').DataTable().destroy()
            this.getHeatmapData();
        }
    },
    methods: {
        getHeatmapData() {
            const url = `/api/ga/ecommerce`;
            let payload = {
                filter: "mom",
                dimension: this.selectedDimension.value,
                metric: this.selectedMetrics.value
            };
            axios.get(url, { params: payload }).then(res => {
                this.processHeatmapData(res.data.data);
                this.loader = false;
            }).catch(err => console.log(err));
        },
        processHeatmapData(data) {
            this.heatmapTable.header = data[0];
            this.heatmapTable.data = data.slice(1);
            this.$nextTick(() => {
                var search = require("@/assets/Icons_SVG/Search.svg");
                $('#heatmap_table').DataTable({
                    searching: true,
                    info: false,
                    lengthChange: true,
                    sorting: [[1, "desc"]],
                    'columnDefs': [{ 'orderable': false, 'targets': 0 }],
                    paging: true,
                    initComplete: function (settings, json) {
                        $('#heatmap_table').wrap("<div style='overflow:auto; width:100%;position:relative;'></div>")
                    },
                    language: {
                        search: "", searchPlaceholder: "Search...", paginate: {
                            first: "",
                            previous: "<",
                            next: ">",
                            last: "",
                        },
                        lengthMenu: "<h5>Show entries</h5> _MENU_"
                    },

                })
                $('#heatmap_table' + '_filter > label').append(
                    `<div class='search_icon'><img src='${search}' /></div>`
                );
            });
        },
        numberFormat(num) {
            var num_parts = num.toString().split(".");
            num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return num_parts.join(".");
        },
    }
}
</script>
<style scoped>
.diagonalWrapper {
    position: relative;
    display: inherit;
}

.belowdiagonalLine {
    position: absolute;
    right: -50px;
    width: 48px;
    top: 2px;

}

.abovediagonalLine {
    position: absolute;
    right: -119px;
    top: -17px;
}

.diagonalLine {
    transform: rotate(17.5deg);
    border: none;
    border-top: 1px solid var(--base_color-40);
    margin-top: 4px;
    margin-right: -5px;
    float: right;
    width: 123px !important;
}

.cityLine {
    transform: rotate(13.5deg);
    border: none;
    border-top: 1px solid var(--base_color-40);
    margin-top: 6px;
    margin-right: -2px;
    float: right;
    width: 139px !important;
}

#heatmap_table>thead>tr>th:first-child {
    max-width: 122px !important;
    padding: 0 !important;
}
</style>