<template lang="pug">
div.w-full#scatter-chart
    highcharts(:options="chartData")
</template>
    
    <!-- props format :-
        data: {
        xAxis: array,
        seriesData: [{name: string, data: array, color: 'color code (optional),zones:'set style for particular line (optional)'}, ...],
        ylabel: string (optional),
        description: string (optional),
        legend: boolean (optional) 
        }
        topMargin: Number (optional)
        legendYPos: Number (optional)
        hideAxis: boolean (optional)
        height: Number (optional)
        tooltipCustom: boolean in string (optional)
        -->
    
<script>
import Highcharts from 'highcharts';
require('highcharts/highcharts-more.js')(Highcharts);

export default {
    name: 'bubble',
    props: ["seriesData", "xAxis", "yAxis", "name", "yAxisName", "xAxisName", "zAxisName"],
    data() {
        return {
            chartData: {},
            color_codes: [
                "#47A694",
                "#eea39f",
                "#c8beea",
                "#79A69E",
                "#54736D",
                "#265950",
                "#79A69E",
                "#317367",
                "#54736D",
                "#52BFAB",
                "#84B5AC",
                "#5F827C",
                "#388273",
                "#59CFB7",
            ],
            updatedGraphData: {
                name: '',
                currentValue: '',
                wasValue: '',
                date: '',
                compDate: ''
            },
            updateData: '',
        }
    },
    watch: {
        seriesData: {
            handler() {
                this.initiate();
            },
            deep: true
        },

    },
    mounted() {
        this.initiate();
    },
    methods: {
        plotYaxisOffset(){
            const chartContainer = document.querySelector("#scatter-chart");
            return -Math.abs(chartContainer.offsetWidth)/2;
        },

        initiate() {
            let self = this
            // const seriesData = [];
            // looping over the seriesData provided such that there could be as many lines as provided.
            this.chartData = {
                chart: {
                    type: 'bubble',
                    color: 2,
                    plotBorderWidth: 1,
                    marginTop:'10',
                    marginBottom:'10',
                    marginLeft: 5,
                    height: '620',
                    zoomType: 'xy',
                    panning: {
                        enabled: true,
                        type: 'x',
                    },
                },
                mapNavigation: {
                    enableMouseWheelZoom: true
                },
                
                legend: {
                    enabled: false
                },

                title: {
                    text: ''
                },
                credits: {
                    enabled: false
                },                
                subtitle: {
                    text: ''
                },
                xAxis: {
                    gridLineWidth: 1,
                    title: {
                        text: 'Sales',
                        align: "high",
                        style: {
                            color: 'blue'
                        }
                    },
                    lineWidth: 1,
                    lineColor: 'blue',
                    offset: -(600 * 0.66 ),
                    startOnTick: false,
                    endOnTick: true,
                    labels: {
                        padding: 20
                    },
                },

                yAxis: {
                    title: {
                        text: 'ACOS',
                        align:'high',
                        rotation: 0,
                        style: {
                            color: 'blue'
                        }
                    },
                    startOnTick: true,
                    endOnTick: true,
                    lineWidth: 1,
                    reversed: true,
                    min: 0,
                    max: this.yAxis.mean*3,
                    lineColor: 'blue',
                    offset: this.plotYaxisOffset()
                },
                tooltip: {
                    formatter: function (tooltip) {
                        return '<b>' + this.point.name + '</b> <br> <b>' + self.xAxisName + ':</b>' + ' ' + (this.x).toLocaleString("en-US") + '<br> <b>' + self.yAxisName + ': </b> ' + ' ' + (this.y).toLocaleString("en-US") + '<br> <b>Spends: </b> ' + ' ' + (this.point.z).toLocaleString("en-US")
                    },
                    // enabled: self.tooltipDisabled,
                    headerFormat: '',
                    shape: 'rect',
                    borderWidth: 0,
                    shadow: {
                        color: "#d6d6d6",
                        opacity: 0.1,
                        width: 10,
                    },
                    borderRadius: 6,
                    shared: true,
                    backgroundColor: "#ffffff",
                    headerFormat: '',
                    style: {
                        fontSize: '14px'
                    },
                },
                series: this.seriesData
            }
        }
    }
}
</script>
    
<style></style>