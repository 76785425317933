import axios from 'axios'
import moment from 'moment'
import Loader from '@/components/Loader/Loader.vue'
import { Chart } from 'highcharts-vue'

export default {
    name: 'FacebookAccountSummary',
    components: {
        Loader,
        Chart
    },
    data() {
        return {
            dateRange: {
                startDate: moment().subtract(1, 'year'),
                endDate: moment()
            },
            activeCompare: false,
            metricDropdown: 'account',
            metricDropdownDetailType: 'adset',
            accountSummaryData: [],
            fbTimelineDaywiseDataLoad: false,
            fbAllSummaryDataLoad: false,
            fbTimelineDaywiseData: {
                header: [
                    { key: "Date", label: "Date" },
                    { key: "CPC", label: "CPC" },
                    { key: "CTR", label: "CTR" },
                    { key: "Clicks", label: "Clicks" },
                    { key: "Impressions", label: "Impressions" },
                    { key: "Reach", label: "Reach" },
                    { key: "Spend", label: "Spend (Rs)" },
                ],
                data: [],
                chart: {
                    chart: {
                        renderTo: 'container',
                        type: 'line'
                    },
                    title: {
                        text: ''
                    },
                    credits: {
                        enabled: false
                    },
                    yAxis: {
                        title: {
                            text: ''
                        }
                    },
                    xAxis: {
                        categories: []
                    },
                    tooltip: {
                        formatter: function () {
                            if (isNaN(this.y)) {
                                return `${this.x} </br> <strong>${this.y}</strong>`
                            }
                            const num1 = Number(this.y).toFixed(2);
                            const num2 = Number(num1).toLocaleString();
                            return `${this.x} </br> <strong>${num2}</strong>`;
                        }
                    },
                    series: [
                        {
                            showInLegend: false,
                            name: '',
                            color: '#47A694',
                            data: []
                        }
                    ],
                    width: '100%'
                },
                metricDropdown: { key: "CTR", label: "CTR" }
            },
            fbAllSummaryData: {
                header: [
                    { key: "Adset Name", label: "Adset" },
                    { key: "CTR", label: "CTR" },
                    { key: "CPC", label: "CPC" },
                    { key: "Reach", label: "Reach" },
                    { key: "Clicks", label: "Clicks" },
                    { key: "Impressions", label: "Impressions" },
                    { key: "Spend", label: "Spend (Rs)" }
                ],
                data: [],
                metricDropdown: 'adset'
            }
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        // Initialize
        init() {
            this.formatDateRange();
            this.dataAPIs();
        },

        destroyAllTables() {
            $('#fb-all-summary-tbl').DataTable().destroy();
            $('#daywise-detail-tbl').DataTable().destroy();
        },


        // Format
        formatDateRange() {
            this.dateRange.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            this.dateRange.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD');
        },

        formatDates() {
            this.formatDateRange();
            this.destroyAllTables()
            this.dataAPIs();
        },


        // APIs
        dataAPIs() {
            this.fbTimelineDaywiseDataLoad = true;
            this.fbAllSummaryDataLoad = true;
            this.getAccountSummaryData();
            this.getFbTimelineDaywiseData();
            this.getFbAllSummaryData();
        },
        getAccountSummaryData() {
            const url = `/api/fb/bulk/account?start_date=${this.dateRange.startDate}&end_date=${this.dateRange.endDate}&version=updated`
            this.accountSummaryData = []
            axios.get(url).then((res) => {
                for (let _metric in res.data.data) {
                    this.accountSummaryData.push({
                        metric: _metric,
                        latestValue: this.formatNumber(res.data.data[_metric]),
                        oldValue: this.formatNumber(res.data.percent_change[_metric]) + '%',
                        icon: res.data.percent_change[_metric] > 0 ? 'fa-up-long' : 'fa-down-long',
                        color: res.data.percent_change[_metric] > 0 ? 'w-green' : 'w-red'
                    })
                    console.log("card data",this.accountSummaryData);
                }
            }).catch((err) => console.log(err))
        },

        getFbTimelineDaywiseData() {
            this.fbTimelineDaywiseDataLoad = true;
            this.fbTimelineDaywiseData.data=[];
            const url = `/api/fb/timeline/account?start_date=${this.dateRange.startDate}&end_date=${this.dateRange.endDate}&breakdown=day`
            axios.get(url).then((res) => {

                this.fbTimelineDaywiseData.data = [...res.data.data]
                this.$nextTick(() => {
                    this.processFbTimelineDaywiseTable();
                });
            }).catch((err) => console.log(err))
        },

        getFbAllSummaryData() {
            this.fbAllSummaryDataLoad = true;
            this.fbAllSummaryData.data = [];
            $('#fb-all-summary-tbl').DataTable().destroy();
            if (this.fbAllSummaryData.metricDropdown === 'adset') {
                this.fbAllSummaryData.header[0] = { key: "Adset Name", label: "Adset" }
            }
            else if (this.fbAllSummaryData.metricDropdown === 'campaign') {
                this.fbAllSummaryData.header[0] = { key: "Campaign Name", label: "Campaign" }
            }
            const url = `/api/fb/account/all/summary?start_date=${this.dateRange.startDate}&end_date=${this.dateRange.endDate}&level=${this.fbAllSummaryData.metricDropdown}`
            axios.get(url).then((res) => {
                this.fbAllSummaryData.data = [...res.data.data]
                this.$nextTick(() => {
                    this.processFbAllSummaryDataTable();
                });
                this.fbAllSummaryDataLoad = false;
            }).catch((err) => {
                this.fbAllSummaryDataLoad = false;
                console.log(err);
            })
        },

        // Tables
        processFbTimelineDaywiseTable() {
            let tableID = `#daywise-detail-tbl`;
            if (this.fbTimelineDaywiseData.data.length > 10) {
                var search = require("@/assets/Icons_SVG/Search.svg");
                $(tableID).DataTable({
                    searching: true,
                    info: false,
                    lengthChange: true,
                    initComplete: function (settings, json) {
                        $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                        $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                        $(tableID).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                    },
                    sorting: [[1, "desc"]],
                    language: {
                        search: "", searchPlaceholder: "Search...", paginate: {
                            first: "",
                            previous: "<",
                            next: ">",
                            last: "",
                        },
                        lengthMenu: "<h5>Show entries</h5> _MENU_"
                    },
                });
                $(tableID + "_filter > label").append(
                    `<div class='search_icon'><img src='${search}' /></div>`
                );
            } else {
                $(tableID).dataTable({
                    searching: false,
                    info: false,
                    lengthChange: false,
                    initComplete: function (settings, json) {
                        $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                        $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                        $(tableID).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                    },
                    sorting: [[0, "desc"]],
                    paginate: false
                })
            }
            this.processFbTimelineDaywiseGraph();
        },

        processFbAllSummaryDataTable() {
            let tableID = `#fb-all-summary-tbl`;
            if (this.fbAllSummaryData.data.length > 10) {
                var search = require("@/assets/Icons_SVG/Search.svg");
                $(tableID).dataTable({
                    searching: true,
                    info: false,
                    lengthChange: true,
                    fixedHeader: true,
                    initComplete: function (settings, json) {
                        $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                        $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                        $(tableID).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                    },
                    sorting: [[0, "desc"]],
                    language: {
                        search: "", searchPlaceholder: "Search...", paginate: {
                            first: "",
                            previous: "<",
                            next: ">",
                            last: "",
                        },
                        lengthMenu: "<h5>Show entries</h5> _MENU_"
                    },
                })
                $(tableID + "_filter > label").append(
                    `<div class='search_icon'><img src='${search}' /></div>`
                );
            } else {
                $(tableID).dataTable({
                    searching: false,
                    info: false,
                    lengthChange: false,
                    initComplete: function (settings, json) {
                        $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                        $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                        $(tableID).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                    },
                    sorting: [[0, "desc"]],
                    paginate: false
                })
            }
        },

        // Graphs
        changeMetricDropdown() {
            this.$emit('change-metric-dropdown', this.metricDropdown)
        },

        processFbTimelineDaywiseGraph() {
            this.fbTimelineDaywiseData.chart.xAxis.categories = []
            this.fbTimelineDaywiseData.chart.series[0].data = []
            this.fbTimelineDaywiseData.chart.yAxis.title.text = this.fbTimelineDaywiseData.metricDropdown.key

            this.fbTimelineDaywiseData.data.forEach(data => this.fbTimelineDaywiseData.chart.xAxis.categories.push(data.Date))
            this.fbTimelineDaywiseData.data.forEach(data => {
                if(data[this.fbTimelineDaywiseData.metricDropdown.key]){
                this.fbTimelineDaywiseData.chart.series[0]['data'].push(data[this.fbTimelineDaywiseData.metricDropdown.key])}})
            console.log("check data", this.fbTimelineDaywiseData.chart)
            this.fbTimelineDaywiseDataLoad = false;
        },


        // Generic function
        formatNumber(num) {
            if (isNaN(num)) {
                return num
            }
            const num1 = Number(num).toFixed(2);
            const num2 = Number(num1).toLocaleString();
            return num2;
        }


    }
}