import Loader from '@/components/Loader/Loader.vue';
import moment from 'moment';
import axios from 'axios';
import { Modal } from 'flowbite';
import scatterGraph from '@/components/charts/scatterChart.vue'
export default {
    name: 'AMS',
    components: {
        Loader, scatterGraph
    },
    data() {
        return {
            dataLoader: false,
            activeCampaign: true,
            dateRange: {
                startDate: moment().subtract(1, 'months').format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD")
            },
            postCampaign: [],
            rulesTableHeader: [],
            rulesTableData: [],
            campaignTableHeader: [],
            campaignTableData: [],
            rulesData: [],
            campaignRules: [],
            ruleName: [],
            rulesOption: [],
            ruleId: [],
            ruleHeading: [],
            rule_id: '',
            metrics: [],
            campaign: [],
            campaignType: [],
            campType: '',
            ruleModal: undefined,
            campModal: undefined,
            campRuleId: '',
            emailError: false,
            selectedLastDate: '',
            selectedLastMonth: '',
            radioSelcted: '',
            checkLastDays: '',
            intervalSelected: '1',
            date: '1',
            addEmail:[],
            // condition:[],

            rules: [
                {
                    "rule_name": "",
                    "conditions": [
                        {
                            "metric": "",
                            "operation": "",
                            "value": null
                        }
                    ]
                },
            ],
            camp_rules: [
                {
                    "rule_id": "",
                    "campaign_id": "",
                    "campaign_name": "",
                    "campaign_type": "",
                    "radioSelcted": '',
                    "selectedLastDate": '',
                    "selectedLastDay": '',
                    'selectedLastMonth': '',
                    "interval": "",
                    "time": "",
                    "alertName": '',
                    "email": ""
                }
            ],
            postCamp: [],
            tempEmail: "",

            // ],
            dropdownOptions2: [
                'Greater Than',
                'Greater Equal',
                'Equal',
                'Less Equal',
                'Less Than',
            ],
            intervalOptions: ['Daily', 'Weekly'],
            LastDate: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
            LastDay: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
            dataLoader1: false,
            viewCampaignData: '',
            selectedCampaignTypeData: [{
                key: "Sponsored Products", label: "sp",
            },
            {
                key: "Sponsored Brands", label: "sb",
            }],
            campaignOptions: [{
                key: "Sponsored Products", label: "sp",
            },
            {
                key: "Sponsored Brands", label: "sb",
            }],
            campaignGraphData: '',
            scatterChartOption: ['Sales', 'Spends'],
            selectedScatterChart: 'Sales',
            headersList: [],
            selectedHeaders: [],
            insightsHeader: [],
            insightsData: [],
        }
    },

    mounted() {
        this.getCampaignTableData();
        this.initializeModal();
        this.getAlertRules();
        this.getCampaignRule();
        // this.getCampaign();

    },
    methods: {
        validEmail(email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        updateDateRange() {
            this.radioSelcted = this.selectedLastDate
        },

        initializeModal() {
            const $targetRule = document.getElementById('rule-modal');
            const ruleOptions = {
                placement: 'center',
                backdrop: 'dynamic',
                backdropClasses: 'bg-gray-900 bg-opacity-50 fixed inset-0 z-40',
                closable: true,
                //     onHide: () => {
                //       this.ruleModal.show()
                //   },
                //   onShow: () => {
                //       this.ruleModal.hide()
                //   }
            };
            const $targetCamp = document.getElementById('camp-modal');
            const campOptions = {
                placement: 'center',
                backdrop: 'dynamic',
                backdropClasses: 'bg-gray-900 bg-opacity-50 fixed inset-0 z-40',
                closable: true,
                // onHide: () => {
                //     this.ruleModal.show()
                // },
                // onShow: () => {
                //     this.ruleModal.hide()
                // }
            };
            this.ruleModal = new Modal($targetRule, ruleOptions);
            this.campModal = new Modal($targetCamp, campOptions);

        },
        addButton() {
            this.ruleModal.show();
            this.getAlertRules();
        },
        addRuleButton() {
            this.campModal.hide()
            this.ruleModal.show();
        },
        addCampButton() {
            this.campModal.show();
            this.getCampaignRule();
            // this.getCampaign();
        },
        hideCampModal() {
            // console.log("modal fire");

            this.campModal.hide()
        },
        hideRuleModal() {
            // console.log("modal fire");

            this.ruleModal.hide()
        },
        closeRuleModal() {
            this.ruleModal.hide();

        },
        formatNumber(num) {
            if (isNaN(num)) return num
            let num1 = Number(num).toFixed(2);
            let num2 = Number(num1).toLocaleString();
            return num2
        },
        formatDateRange() {
            this.dateRange.startDate = moment(this.dateRange.startDate).format("YYYY-MM-DD");
            this.dateRange.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
        },
        formatDates() {
            this.formatDateRange();
            // this.getCampaignTableData();
            // this.topAsins();
        },

        getCampaignTableData() {
            this.viewCampaignData = ''
            this.dataLoader = true
            let url = "/api/amazon/campaigns/get_campagins"
            let adType = [];
            if(this.selectedHeaders.length){
                this.selectedHeaders.unshift("Campaigns")
            }
            let payload = {
                "start_date": this.dateRange.startDate,
                "end_date": this.dateRange.endDate,
                fields: this.selectedHeaders
            }
            if(this.activeCampaign) payload = {...payload, camp_state: "Enabled"}
            else payload = {...payload, camp_state: "All"}
            
            if(this.selectedCampaignTypeData.length === 1){
                adType = this.selectedCampaignTypeData[0].key
            } else if(!this.selectedCampaignTypeData.length) {
                adType = []
            } else {
                this.selectedCampaignTypeData.forEach(element => {
                    adType.push(element.key)
                });
            }
            payload = {...payload, ad_type: adType}

            axios.post(url, payload ).then(res => {
                this.viewCampaignData = res.data.data;
                this.headersList = res.data.data.campaign_header;
                this.insightsHeader = res.data.data.insight_graph_header;
                this.insightsData = res.data.data.insight_data;
                this.headersList.splice(0,1);

                this.$nextTick(() => {
                    this.processGaraphData(res.data.data)
                    this.processTable('#campaignTable-tbl', 4);
                    this.processTable('#insights-table', 1);
                })
                this.dataLoader = false

            }).catch(err => {
                console.log("err", err)
                this.dataLoader = false

            })
        },
        processGaraphData(data) {
            this.campaignGraphData = {}
            this.campaignGraphData = {
                xAxisName: "Sales",
                yAxisName: "ACOS",
                zAxisName: 'Spends',
                ACOSMaxValue: '',
                xAxis: {
                    min: data.min_max[this.selectedScatterChart].min,
                    max: data.min_max[this.selectedScatterChart].max,
                    mean: data.min_max[this.selectedScatterChart].mean
                },
                yAxis: {
                    min: data.min_max.ACOS.min,
                    max: data.min_max.ACOS.max,
                    mean: data.min_max.ACOS.mean
                },
                zAxis: {
                    min: data.min_max.Spends.min,
                    max: data.min_max.Spends.max,
                    mean: data.min_max.Spends.mean
                },
                seriesData: [{
                    name: 'ACOS VS ' + this.selectedScatterChart,
                    data: [],
                    colorByPoint: true

                }]
            }
            let color = [
                "#47A694",
                "#eea39f",
                "#c8beea",
                "#79A69E",
                "#54736D",
                "#265950",
                "#79A69E",
                "#317367",
                "#54736D",
                "#52BFAB",
                "#84B5AC",
                "#5F827C",
                "#388273",
                "#59CFB7",
            ]
            for (let row of data.graph_data) {
                this.campaignGraphData.seriesData[0].data.push({ name: row['Campaigns'].value, x: row[this.selectedScatterChart].value, y: row.ACOS['value'], z: row.Spends['value'] })
            }
        },

        toggleDropdown() {
            this.showDropdown = !this.showDropdown;
        },

        addItem(index) {
            // // console.log('another field',this.rules[index]);
            this.rules[index].conditions.push(
                {
                    "metric": "",
                    "operation": "",
                    "value": null
                }
            )
        },

        addRules() {
            // // console.log('another rule',this.rules);
            this.rules.push(
                {
                    "rule_name": "",
                    "conditions": [
                        {
                            "metric": "",
                            "operation": "",
                            "value": null
                        }
                    ]
                }

            )

        },
        removeRules(idx) {
            this.rules.splice(idx, 1)
        },
        multipleEmail(email,campIndex){
            this.addEmail.push(email);
            this.camp_rules[campIndex].email='';
        },
        removeMultipleEmail(campIndex,emailIndex){
    this.addEmail[campIndex].splice(emailIndex,1)
        },

        getCampaignRule() {
            this.dataLoader1 = true;
            this.campaignType = []
            let url = '/api/amazon/alert/campaign_rule'
            axios.get(url).then(res => {
                const resData = res.data;
                this.dataLoader1 = false;
                // console.log('camp',resData);
                // this.camp_rules=resData.data.campaign_rules;
                // console.log("campaign rule",this.camp_rules);
                this.campaignType = resData.data.campaign_types;
                if (this.campaignType.length != 0) {
                    this.campaignType.unshift({
                        "key": "ALL",
                        "label": "all"
                    })
                }

                this.campaignTableHeader = resData.data.headers;
                this.campaignTableData = resData.data.campaign_rules
                this.$nextTick(() => {
                    this.processTable('#campaignRuleTable');
                })
                this.dataLoader1 = false;
            }).catch(err => {
                console.log(err);
                this.dataLoader1 = false
            }
            )

        },
        getCampaign(type) {
            this.dataLoader = true;
            let key = []
            this.campaign = []
            if (type.length) {
                if (type[0].label == 'all') {
                    for (let data of this.campaignType) {
                        if (data.label != 'all')
                            key.push(data.label)
                    }
                }
                else {
                    for (let data of type) {
                        key.push(data.label)
                    }
                }
            }
            else {
                this.dataLoader = false;
            }

            let url = '/api/amazon/alert/campaigns?ad_type=' + key.toString()
            axios.get(url).then(res => {
                const resData = res.data;
                this.dataLoader = false;
                this.campaign = resData.data;
                if (this.campaign) {
                    this.campaign.unshift({
                        'camp_id': 'all',
                        'camp_name': 'ALL',
                    })
                }
            }).catch(err => {
                console.log("err", err)
                this.dataLoader = false;
            })
        },
        // chevalu(check){
        //     console.log("sdfklsdjf",check)
        // },
        postCampaignRule() {
            this.postCampaign = [];
            this.dataLoader = true;
            for (let Element of this.camp_rules) {
                let temp = {
                    rule_id: Element.rule_id.map(value => { return value.rule_id }),
                    campaign_id: Element.camp_id[0].camp_id == 'all' ? [] : Element.camp_id.map(value => { return value.camp_id }),
                    campaign_name: Element.camp_id[0].camp_name == 'ALL' ? [] : Element.camp_id.map(value => { return value.camp_name }),
                    campaign_type: Element.campaign_type[0].label == 'all' ? [] : Element.campaign_type.map(value => { return value.label }),
                    interval: Element.intervalSelected,
                    run_at: Element.time,
                    alert_name: Element.alertName,
                    email: Element.email,
                    data_duration: Element.radioSelcted == 0 ? 0 : Element.selectedLastDate
                }
                if (!this.validEmail(Element.email)) {
                    this.emailError = true
                    return false;
                }

                if (temp.interval == "weekly") {
                    Object.assign(temp, { day_of_week: Element.selectedLastDay })
                    // temp.day_of_week == Element.selectedLastDay
                }
                if (temp.interval == 'monthly') {
                    Object.assign(temp, { day_of_month: Element.selectedLastMonth })
                    // temp.day_of_month == Element.selectedLastMonth
                }
                this.postCampaign.push(temp)

            }

            // console.log("array",this.postCampaign);
            // console.log("campppp",this.camp_rules);
            let url = '/api/amazon/alert/campaign_rule'

            let payload = {
                "camp_rules": this.postCampaign
            }


            axios.post(url, payload).then((res) => {
                this.camp_rules = [
                    {
                        "rule_id": "",
                        "campaign_id": "",
                        "campaign_name": "",
                        "campaign_type": "",
                        "radioSelcted": '',
                        "selectedLastDate": '',
                        "selectedLastDay": '',
                        "selectedLastMonth": '',
                        "interval": "",
                        "time": "",
                        "alertName": '',
                        "email": ""
                    }

                ]
                this.$toast.success("Rule Added Successfully", {
                    position: "bottom-center",
                    timeout: 5000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false
                });
                this.hideCampModal();
                this.dataLoader = false
            }).catch(err => {
                console.log(err);
                this.dataLoader = false
            })
            this.getCampaignRule();
            // console.log("payload",payload);
        },
        getAlertRules() {
            this.dataLoader = true;
            let url = '/api/amazon/alert/rules'
            axios.get(url).then(res => {
                const resData = res.data
                this.metrics = resData.data.metrices;
                this.rulesTableHeader = resData.data.headers;
                this.rulesTableData = resData.data.dashboard_data;
                this.rulesTableHeader.push({ key: 'action', label: "Action" })
                if (resData.data.rules.length) this.campaignRules = resData.data.rules
                this.$nextTick(() => {
                    this.processTable('#ruleTable');
                })
                this.dataLoader = false;
            }).catch(err => {
                console.log(err);
                this.dataLoader = false
            }
            )
        },
        postAlertRules() {
            this.dataLoader = true;

            let url = '/api/amazon/alert/rules'
            let payload = {
                "rules": this.rules
            }
            axios.post(url, payload).then((res) => {
                // alert("Rule Added Successfully");
                this.dataLoader = false;
                this.rules = [{
                    "rule_name": "",
                    "conditions": [
                        {
                            "metric": "",
                            "operation": "",
                            "value": null
                        }
                    ]
                }]
                this.$toast.success("Added Successfully", {
                    position: "bottom-center",
                    timeout: 5000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false
                });
                this.getAlertRules();
                this.closeRuleModal();
            }).catch(err => {
                console.log(err);
                this.dataLoader = false
            }
            )

            // console.log("payload",payload);
        },
        deleteAlertRules(deleteRuleId) {
            this.dataLoader = true;
            let url = '/api/amazon/alert/rule_delete'
            let payload = {
                "rule_id": deleteRuleId
            }
            if (confirm("Are you sure you want to delete?")) {
                axios.post(url, payload).then((res) => {
                    // alert("Rule delete Successfully");

                    location.reload();
                    this.dataLoader = false;
                }).catch(err => {
                    console.log(err);
                    this.dataLoader = false
                }
                )
            } else {
                this.dataLoader = false;
                return false;
            }

            // console.log('delete rule',payload);
        },
        deleteCampaignRules(deleteCampId) {
            this.dataLoader = true;
            let url = '/api/amazon/alert/camp_rule_delete'
            let payload = {
                "alert_id": deleteCampId
            }
            if (confirm("Are you sure you want to delete?")) {
                axios.post(url, payload).then((res) => {
                    // alert("Rule delete Successfully");

                    location.reload();
                    this.dataLoader = false;
                })
            } else {
                this.dataLoader = false;
                return false;
            }
            // console.log("delete camp",payload);
        },

        decodeHTML(html){
			txt = document.createElement('textarea')
			txt.innerHTML = html;
			return txt.value 
        },

        processTable(id, sortCol=0) {
                $(id).DataTable().destroy();
                var search = require("@/assets/Icons_SVG/Search.svg");
                $(id).DataTable({
                    searching: true,
                    info: false,
                    sorting: [[sortCol, "desc"]],
                    initComplete: function (settings, json) {
                        $(id).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                    },
                    language: {
                        search: "",
                        searchPlaceholder: "Search...",
                        paginate: {
                            first: "",
                            previous: "<",
                            next: ">",
                            last: "",

                        },
                        lengthMenu: "<h5>Show entries</h5> _MENU_",
                    },
                })
                $(id + "_filter > label").append(
                    `<div class='search_icon'><img src='${search}' /></div>`
                );
        },
    },
}