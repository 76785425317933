<template lang="pug">
div(:class="card ? 'w-card mb-4' : 'my-4'")
    div(v-if="loader")
        Loader
    div(v-if="!loader")
        div(class="flex justify-between items-center")
            h2(v-if="(tableTitle !== 'Channel' && tableTitle !== 'Gender')"
                class="2xl:text-xl xl:text-base font-semibold text-primary_brand mb-4") {{ tableTitle }}
            div(v-if="tableTitle === 'Location'" class="customMultiselect 2xl:w-56 xl:w-44 ")
                multiselect(v-model="selectedLocation" :options="locationArr" :searchable="false"
                    :close-on-select="true" :show-labels="false" :multiple="false" :allow-empty="false" track-by="value"
                    label="label" :preselect-first="false" @select="changeLocation")

        table(:id="filter.key + 'Table'")
            thead
                tr
                    th(v-for="headerItem, index in tableHeader" :key="headerItem + index") {{ headerItem.label }}
            tbody
                tr(v-for="row, index in tableData" :key="row + index")
                    td(v-for="header, index in tableHeader" :key="header + index" :title="header.label")
                        span(v-if="typeof (row[header.key]) === 'number'") {{ numberFormat(row[header.key].toFixed(2).replace(/[.,]00$/, "")) }}
                        span(v-else) {{ row[header.key] }}
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import Loader from '@/components/Loader/Loader.vue'
export default {
    name: "DataTable",
    props: {
        dateRange: {
            type: Object,
            required: true
        },
        filter: {
            type: Object,
            required: true
        },
        locationArr: {
            type: Array,
            required: false
        },
        card: {
            type: Boolean,
            required: false
        }
    },
    components: { Loader },
    data() {
        return {
            loader: false,
            tableHeader: [],
            tableData: [],
            tableTitle: "",
            selectedLocation: "",
            has_ecom: JSON.parse(localStorage.userData).menu.Ecomm
        }
    },
    mounted() {
        if (this.has_ecom) {
            this.getTableData();
        } else if (!this.has_ecom) {
            this.getNonEcomTableData();
        }
    },
    methods: {
        getTableData() {
            const url = `/api/ga/ecommerce`;
            this.loader = true;
            let payload = {
                filter: this.filter.key,
                channel: "all",
                magento: false,
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD")
            }
            if (this.filter.key === 'month') {
                payload.start_date = moment(this.dateRange.endDate).subtract(6, 'months').format("YYYY-MM-DD")
            }
            if (this.filter.title === 'Location') {
                if (this.filter.key === "") {
                    payload.filter = "city";
                    this.selectedLocation = this.locationArr[0];
                } else if (this.filter.key === 'city') {
                    this.selectedLocation = this.locationArr[0];
                } else if (this.filter.key === 'country') {
                    this.selectedLocation = this.locationArr[1];
                }
            }

            axios.get(url, { params: payload }).then(res => {
                this.tableData = res.data.data;
                this.processTable();
                this.$nextTick(() => {
                    this.plotTable()
                })
                this.loader = false;
            }).catch(err => {
                console.log(err);
                this.loader = false;
            })
        },
        getNonEcomTableData() {
            const url = `/api/ga/non-ecom/reports`;
            this.loader = true;
            let payload = {};
            if (this.filter.key === 'month') {
                Object.assign(payload, {
                    filter: "timelineByChannel",
                    level_type: "view",
                    start_date: moment(this.dateRange.endDate).subtract(6, 'months').format("YYYY-MM-DD"),
                    end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                    timeline_type: "month",
                    channel: "all"
                })
            } else {
                Object.assign(payload, {
                    filter: this.filter.key,
                    start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                    end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                    level_type: "view",
                    timeline_type: "date"
                })
                if (this.filter.title === 'Location') {
                    if (this.filter.key === "") {
                        payload.filter = "city";
                        this.selectedLocation = this.locationArr[0];
                    } else if (this.filter.key === 'city') {
                        this.selectedLocation = this.locationArr[0];
                    } else if (this.filter.key === 'country') {
                        this.selectedLocation = this.locationArr[1];
                    }
                }
            }
            axios.get(url, { params: payload }).then(res => {
                this.tableData = res.data.data;
                this.processTable();
                this.$nextTick(() => {
                    this.plotTable()
                })
                this.loader = false;
            }).catch(err => {
                console.log(err);
                this.loader = false;
            })
        },
        processTable() {
            this.loader = true;
            this.tableTitle = this.filter.title
            this.tableHeader = this.filter.header;
            if (this.selectedLocation.value === 'city') {
                this.tableHeader[0].key = 'city';
            } else if (this.selectedLocation.value === 'country') {
                this.tableHeader[0].key = 'country'
            }
            this.loader = false;
        },
        plotTable() {
            let tableID = `#${this.filter.key}Table`;
            let wrapDiv;
            if(this.filter.title === 'Location') {
                wrapDiv = "<div style='overflow:auto; width:100%;position:relative; max-height: 300px;'></div>";
            } else {
                wrapDiv = "<div style='overflow:auto; width:100%;position:relative; max-height: 500px;'></div>";
            }
            if (this.tableData.length > 10) {
                var search = require("@/assets/Icons_SVG/Search.svg");
                $(tableID).DataTable({
                    searching: true,
                    info: false,
                    lengthChange: true,
                    sorting: [[this.filter.sortCol, "desc"]],
                    initComplete: function (settings, json) {
                        $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                        $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                        $(tableID).wrap(wrapDiv)
                    },
                    language: {
                        search: "", searchPlaceholder: "Search...", paginate: {
                            first: "",
                            previous: "<",
                            next: ">",
                            last: "",
                        },
                        lengthMenu: "<h5>Show entries</h5> _MENU_"
                    },
                })
                $(tableID + "_filter > label").append(
                    `<div class='search_icon'><img src='${search}' /></div>`
                );
            } else {
                $(tableID).dataTable({
                    searching: false,
                    info: false,
                    lengthChange: false,
                    initComplete: function (settings, json) {
                        $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                        $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                        $(tableID).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                    },
                    sorting: [[this.filter.sortCol, "desc"]],
                    paginate: false
                })
            }
            if (this.filter.key === 'channelGrouping') {
                this.channelDataStorage(this.tableData);
            }
            if (this.filter.key === 'userGender') {
                this.genderDataStorage(this.tableData);
            }
            if (!this.has_ecom && this.filter.key === 'month') {
                this.monthDataStorage(this.tableData)
            }
        },
        numberFormat(num) {
            var num_parts = num.toString().split(".");
            num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return num_parts.join(".");
        },
        changeLocation() {
            this.$emit('change-location', this.selectedLocation)
        },
        channelDataStorage(data) {
            this.$emit('channel-storage', data)
        },
        genderDataStorage(data) {
            this.$emit('gender-storage', data)
        },
        monthDataStorage(data) {
            this.$emit('month-storage', data)
        }
    },
}
</script>